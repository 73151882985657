<template>
  <ion-item
    lines="full" detail="true" data-testid="select-timeslot-trigger"
    button="true" @click="timeslotCalendarModalRef.$el.present()"
  >
    <ion-chip
      v-show="!isValid" slot="start" color="danger"
    >
      No Timeslot Selected
    </ion-chip>
    <ion-label v-show="isValid">
      <strong>{{ lesson.formattedDate }}, {{ displayFullLength(lesson.time, lesson.length) }}</strong>
    </ion-label>
    <ion-modal
      id="timeslots-calendar-modal" ref="timeslotCalendarModalRef" data-testid="timeslots-calendar-modal"
    >
      <div style="max-height: 90%">
        <timeslots-calendar
          v-if="lesson.lessonType"
          :instructor="instructor"
          :selected-location="lesson.lessonType.location" :selected-pricing-type="lesson.pricing.key"
          :current-user="'instructor'"
          @timeslot:selected="selectTimeslot"
        />
      </div>
    </ion-modal>
  </ion-item>
</template>

<script setup>
import { IonChip, IonItem, IonLabel, IonModal } from '@ionic/vue'
import { ref } from 'vue'

import TimeslotsCalendar from '@/views/golfers/lessons/TimeslotsCalendar.vue'
import { displayFullLength } from '@/6_shared/utils/formattingLessons.js'

defineProps({
  lesson: { type: Object, required: true },
  instructor: { type: Object, required: true },
  isValid: { type: Boolean, required: true },
})
const emit = defineEmits(['selectTimeslot'])

const timeslotCalendarModalRef = ref()

async function selectTimeslot(timeslot) {
  emit('selectTimeslot', timeslot)
  timeslotCalendarModalRef.value.$el.dismiss()
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#timeslots-calendar-modal {
  --width: 90%;
  --min-width: 250px;
  --max-width: 90%;
  --height: fit-content;
  --max-height: 80%;
  --overflow: auto;
  --border-radius: 14px;
}</style>
