<template>
  <ion-spinner v-if="isLoading" />
  <ion-card v-if="!isLoading" style="margin-top:3em">
    <ion-card-header>
      <ion-card-title>
        <ion-icon :icon="calendarOutline" />
        Subscribe to your Calendar
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p class="ion-padding-bottom" style="padding-top: 0">Stay up-to-date with your schedule by subscribing to your personal calendar feed. Choose your preferred calendar application:</p>
      <ion-segment v-model="selectedCalendarApp" scrollable="true">
        <ion-segment-button value="webcal">
          <ion-label>Apple</ion-label>
          <ion-icon :icon="logoApple" />
        </ion-segment-button>
        <ion-segment-button value="google">
          <ion-label>Google</ion-label>
          <ion-icon :icon="logoGoogle" />
        </ion-segment-button>
        <ion-segment-button value="outlook">
          <ion-label>Outlook</ion-label>
          <ion-icon :icon="logoMicrosoft" />
        </ion-segment-button>
        <ion-segment-button value="other">
          <ion-label>Other</ion-label>
          <ion-icon :icon="calendarOutline" />
        </ion-segment-button>
      </ion-segment>

      <div v-for="calendarType in availableCalendarTypes" :key="calendarType" class="ion-margin-top">
        <template v-if="selectedCalendarApp == 'webcal'">
          <ion-button
            size="small" fill="outline" color="success"
            expand="block" @click="subscribeToCalendar(calendarType, 'webcal')"
          >
            <ion-icon :icon="logoApple" />
            &nbsp;Subscribe to {{ calendarType === 'lessons' ? 'Lessons' : 'Availability' }}
          </ion-button>
        </template>

        <template v-if="selectedCalendarApp == 'google'">
          <ion-button
            size="small" fill="outline" color="success"
            expand="block" class="ion-margin-top" @click="subscribeToCalendar(calendarType, 'google')"
          >
            <ion-icon :icon="logoGoogle" />
            &nbsp;Subscribe to {{ calendarType === 'lessons' ? 'Lessons' : 'Availability' }}
          </ion-button>
        </template>
        <template v-if="selectedCalendarApp == 'outlook'">
          <ion-button
            size="small" fill="outline" color="success"
            expand="block" class="ion-margin-top" @click="subscribeToCalendar(calendarType, 'outlook')"
          >
            <ion-icon :icon="logoMicrosoft" />
            &nbsp;Subscribe to {{ calendarType === 'lessons' ? 'Lessons' : 'Availability' }}
          </ion-button>
        </template>

        <template v-if="selectedCalendarApp == 'other'">
          <ion-textarea
            readonly="true" :value="calendarUrls(calendarType).webcal" :label="`${calendarType === 'lessons' ? 'Lessons' : 'Availability'} iCal URL:`"
            label-placement="stacked" rows="2" mode="md"
            auto-grow="true"
            fill="outline" class="ion-margin-top"
          />
        </template>
      </div>
      <p v-if="selectedCalendarApp == 'webcal'">Updates every 5 minutes (configurable).</p>
      <div v-if="selectedCalendarApp == 'google'">
        <p>Updates every 12–24h.<br>Google controls update timing.</p>
        <p v-if="isPlatform('android')">
          <strong style="color: var(--ion-color-danger)">Android Users:</strong> The Google Calendar mobile app doesn't support adding new calendar URL subscriptions. Add this calendar to Google Calendar from a <strong>desktop browser</strong>.
        </p>
      </div>
      <p v-if="selectedCalendarApp == 'outlook'">Updates every 12–24h.<br>Outlook controls update timing.</p>
      <p v-if="selectedCalendarApp == 'other'">
        You can add this URL to your calendar app manually.
        This URL is unique and grants access to your personal schedule. Protect it to maintain privacy.
        <ul>
          <li><strong style="color: var(--ion-color-danger)">Do not share it with anyone!</strong></li>
          <li>Contact support if you suspect it's been compromised.</li>
        </ul>
      </p>
      <div class="ion-margin-top">
        <hr>
        <h2>Saved Timezone: <strong>{{ userStore.userData().timezone }}</strong></h2>
        <ion-button size="small" fill="outline" @click="updateTimezone(_manual = true)">Update Timezone</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  IonIcon, IonLabel, IonSegment, IonSegmentButton,
  IonSpinner, IonTextarea, isPlatform,
} from '@ionic/vue'
import { computed, onMounted, ref } from 'vue'

import { calendarOutline, logoApple, logoGoogle, logoMicrosoft } from 'ionicons/icons'
import { SM } from '@/globals'
import { createCalendarUrl } from '@/firebase'
import { useUserStore } from '@/stores/user'

const selectedCalendarApp = ref('webcal')
const selectedCalendarType = ref('lessons')

const FUNCTION_URLS = {
  lessons: SM.env === 'development'
    ? 'http://127.0.0.1:5001/swingmatch-8a936/us-west1/generateCalendarFeed'
    : 'https://generateCalendarFeed-aque4yeqwa-uw.a.run.app/generateCalendarFeed',
  availability: SM.env === 'development'
    ? 'http://127.0.0.1:5001/swingmatch-8a936/us-west1/generateAvailabilityFeed'
    : 'https://generateAvailabilityFeed-aque4yeqwa-uw.a.run.app/generateAvailabilityFeed',
}

const userStore = useUserStore()

const isLoading = ref(true)
const availableCalendarTypes = ref(['lessons'])
onMounted(async () => {
  if (!userStore.userData().calendarFeedUuid) {
    createUrl()
  } else {
    isLoading.value = false
  }
  if (userStore.userData().type === 'instructor') {
    availableCalendarTypes.value.push('availability')
  }

  setTimeout(updateTimezone, 2000)
})

async function updateTimezone(manual = false) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  await userStore.fn.updateProfile({ timezone })
  if (manual) alert('Timezone updated')
}

async function createUrl() {
  isLoading.value = true
  const response = await createCalendarUrl()
  isLoading.value = false
  console.log(response)
}

const calendarTypeUrls = computed(() => {
  const uuid = userStore.userData().calendarFeedUuid
  if (!uuid) return { lessons: '', availability: '' }

  return {
    lessons: `${FUNCTION_URLS.lessons}?id=${uuid}`,
    availability: `${FUNCTION_URLS.availability}?id=${uuid}`,
  }
})

const calendarUrls = function (calendarType) {
  const selectedUrl = calendarTypeUrls.value[calendarType]
  const encodedUrl = encodeURIComponent(selectedUrl)
  return {
    google: `https://calendar.google.com/calendar/r?cid=${encodedUrl.replace('https', 'http')}`,
    webcal: `webcal://${selectedUrl.replace('https://', '').replace('http://', '')}`,
    outlook: `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addsubscription&url=${encodedUrl}&name=SwingMatch%20${selectedCalendarType.value === 'lessons' ? 'Lessons' : 'Availability'}`,
  }
}

function subscribeToCalendar(calendarType, calendarApp) {
  if (calendarApp in calendarUrls(calendarType)) {
    window.open(calendarUrls(calendarType)[calendarApp], '_blank')
  } else {
    console.error(`Unsupported calendar calendarApp: ${calendarApp}`)
  }
}
</script>

<style scoped>
p {
  padding-top: 8px;
}
ul {
  margin-top: 8px;
  margin-bottom: 0;
}
</style>
