import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore'
import { computed, toValue } from 'vue'
import { firestoreDefaultConverter, useCollection, useCurrentUser, useDocument, useFirestore } from 'vuefire'
import { defineStore } from 'pinia'

import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import { trackEvent } from '@/globals'
const { generateId } = require('@functions/_shared_src/generateId.js')
import { useChatsStore } from '@/5_entities/chats/store'

const db = useFirestore()

const UsersConverter = {
  toFirestore: user => user.toFirestore(),
  fromFirestore: (snapshot, options) => {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    data.id ||= snapshot.id
    return data
  },
}

export const useUserStore = defineStore('user', () => {
  function clearEmptyPricing(pricing) {
    // filter out empty pricing values
    return Object.fromEntries(
      // filter out empty lessonType.pricing values without price and with enabled: false
      Object.entries(pricing).filter(([key, { price, enabled }]) => ALL_PRICING_TYPES[key] && price && enabled),
    )
  }

  function clearEmptySchedule(schedule) {
    // filter out empty schedule values
    const repeatDays = schedule.dates
    repeatDays.days = repeatDays.days.filter(({ times }) => times.length)

    repeatDays.days = repeatDays.days.filter(({ when }) => {
      return new Date() - new Date(when) < 3 * 24 * 60 * 60 * 1000
    })
    repeatDays.days = repeatDays.days.sort((a, b) => a.when.localeCompare(b.when))
    // merge same days, sort and remove duplicates
    repeatDays.days = repeatDays.days.reduce((acc, day) => {
      const when = day.when
      const times = day.times.sort()
      const index = acc.findIndex(({ when: w }) => w === when)
      if (index === -1) {
        acc.push(day)
      } else {
        acc[index].times.push(...times)
        acc[index].times = [...new Set(acc[index].times)].sort()
      }
      return acc
    }, [])
    return schedule
  }

  const user = useCurrentUser()
  const userRef = computed(() => user.value && doc(db, `users/${user.value.uid}`))
  const userDoc = useDocument(userRef)

  function saveUser(from, object = userDoc.value) {
    setDoc(userRef.value, object, { merge: true })
  }

  const userPrivateRef = computed(() => user.value && doc(db, `users/${user.value.uid}/private/data`))
  const userPrivateDoc = useDocument(userPrivateRef)
  const instructorStudentsRef = computed(() => user.value && collection(db, `users/${user.value.uid}/students`))

  return {
    userDocRef: userRef,
    userPrivateRef,
    userData() {
      return userDoc.value
    },
    is: {
      student: computed(() => userDoc.value?.type === 'student'),
      instructor: computed(() => userDoc.value?.type === 'instructor'),
      type: computed(() => userDoc.value?.type),
      demoStudent: computed(() => userDoc.value?.name?.toLowerCase().includes('demo student')),
      demoInstructor: computed(() => ['cdotto@ualberta.ca', 'desanko@ualberta.ca', 'ildar.abdulin@gmail.com'].includes(userDoc.value?.email)),
    },
    fn: {
      updateProfile: (profileObj) => {
        saveUser('updateProfile', profileObj)
      },
    },
    student: {
      fn: {
        likeUser: async (userId) => {
          userPrivateDoc.value ||= {}
          userPrivateDoc.value.likedUsers ||= {}
          userPrivateDoc.value.likedUsers[userId] = !userPrivateDoc.value.likedUsers[userId]
          if (userPrivateDoc.value.likedUsers[userId]) {
            trackEvent('liked_instructor')
          } else {
            trackEvent('unliked_instructor')
          }
          await setDoc(userPrivateRef.value, userPrivateDoc.value)
        },
        getInstructor(instructorId) {
          const docRef = computed(() => {
            const id = toValue(instructorId)
            if (!id) return null
            return doc(db, `users/${id}`)
          })
          return useDocument(docRef)
        },
        async handleStudentWithSavedInstructorSignUp(savedInstructorId) {
          sessionStorage.removeItem('instructorId')
          await this.likeUser(savedInstructorId)

          const chatsStore = useChatsStore()
          const chatId = [user.value.uid, savedInstructorId].sort().join('---')
          await chatsStore.init(chatId)
          await chatsStore.addMessage(chatId, 'Hi, I just signed up from your instructor page!', user.value.uid)
        },
      },
    },
    instructor: {
      locations: computed(() => {
        return (userDoc.value?.instructor?.locations || []).sort((a, b) => a.name.localeCompare(b.name))
      }),
      lessonTypes: computed(() => {
        return (userDoc.value?.instructor?.lessonTypes || [])
      }),
      fn: {
        // selectLocations: function (locations) {
        //   userDoc.value.instructor ||= {}
        //   userDoc.value.instructor.locations = locations
        //   saveUser()
        // },
        addLessonType: function (lessonType) {
          lessonType.userId = userDoc.value.id
          lessonType.pricing = clearEmptyPricing(lessonType.pricing)
          lessonType.schedule = clearEmptySchedule(lessonType.schedule)

          // add or update lessonType to lessonTypes array
          userDoc.value.instructor ||= {}
          userDoc.value.instructor.lessonTypes ||= []
          const index = userDoc.value.instructor.lessonTypes.findIndex(({ uuid }) => uuid === lessonType.uuid)
          if (index === -1) {
            userDoc.value.instructor.lessonTypes.push(lessonType)
          } else {
            userDoc.value.instructor.lessonTypes[index] = lessonType
          }
          saveUser('addLessonType')
          trackEvent('schedule_updated', { pricing: Object.keys(lessonType.pricing).join(', ') })
        },
        removeLessonType: (lessonType) => {
          userDoc.value.instructor.lessonTypes ||= []
          const index = userDoc.value.instructor.lessonTypes.findIndex(({ uuid }) => uuid === lessonType.uuid)
          if (index !== -1) {
            userDoc.value.instructor.lessonTypes.splice(index, 1)
          }
          saveUser('removeLessonType')
          trackEvent('schedule_removed')
        },
        async updateOfflineStudent(userId, data) {
          data.id ||= userId || `offline_${generateId()}`
          await setDoc(doc(instructorStudentsRef.value, data.id), data)
        },
        async deleteOfflineStudent(studentId) {
          await deleteDoc(doc(instructorStudentsRef.value, studentId))
        },
        getOfflineStudents() {
          return useCollection(instructorStudentsRef.value)
        },
        getOfflineStudent(studentId) {
          return useDocument(doc(instructorStudentsRef.value, studentId).withConverter(UsersConverter))
        },
        getStudent(studentId) {
          if (studentId.startsWith('offline_')) {
            return this.getOfflineStudent(studentId)
          } else {
            return useDocument(doc(db, `users/${studentId}`).withConverter(UsersConverter))
          }
        },
      },
    },
  }
})
