import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import { firestoreDefaultConverter } from 'vuefire'
import { lessonTypePackages } from '@/6_shared/utils/formattingLessons'

export class Instructor {
  constructor(attrs) {
    Object.assign(this, attrs)
    this.id = attrs.id
  }

  cities() {
    const allCities = (this.instructor.lessonTypes || []).map(lessonType => lessonType.location.city)
    return [...new Set(allCities)]
  }

  locations() {
    const allLocations = (this.instructor.lessonTypes || []).map(lessonType => lessonType.location)
    return [...new Set(allLocations)]
  }

  allPricingTypes() {
    const all = new Set(
      this.instructor.lessonTypes?.flatMap(lessonType =>
        Object.keys(lessonType.pricing || {}),
      ) || [],
    )
    return Object.keys(ALL_PRICING_TYPES).filter(pricingType => all.has(pricingType))
  }

  teaches(pricingType) {
    return this.allPricingTypes().includes(pricingType)
  }

  lessonTypes() {
    return this.instructor.lessonTypes || []
  }

  packages() {
    const result = []
    this.lessonTypes().forEach((lessonType) => {
      result.push(...lessonTypePackages(lessonType, this))
    })
    return result
  }
}

export const InstructorsConverter = {
  toFirestore: lesson => lesson.toFirestore(),
  fromFirestore: (snapshot, options) => {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    return new Instructor(data)
  },
}
