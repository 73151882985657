<template>
  <ion-page>
    <ion-content v-if="profile" class="ion-padding" color="light">
      <template v-if="!profile.type">
        <h1 v-if="!profile.onboarded">Welcome to SwingMatch!</h1>
        <p>
          Are you here to teach or learn?
          <br>
          Choose your role to get started:
        </p>
        <ion-segment v-model="tempProfile">
          <ion-segment-button value="student">
            <ion-label>Student</ion-label>
            <ion-icon :icon="schoolOutline" />
          </ion-segment-button>
          <ion-segment-button value="instructor">
            <ion-label>Instructor</ion-label>
            <ion-icon :icon="golfOutline" />
          </ion-segment-button>
        </ion-segment>
        <p v-if="tempProfile">
          <ion-button expand="block" @click="selectProfileType">
            Confirm
          </ion-button>
        </p>
      </template>
      <template v-if="profile.type">
        <ion-list :inset="false" class="ion-margin-top">
          <avatar-upload :uid="profile?.id" :current-avatar="profile.photoURL" @update:avatar="updateAvatar" />
          <ion-item lines="full">
            <ion-input
              v-model="profile.email" label="Email" label-placement="stacked"
              disabled="true"
            />
          </ion-item>
          <ion-item lines="full">
            <ion-input
              v-model="profile.firstName" label="First Name" label-placement="stacked"
              autocomplete="given-name"
            />
          </ion-item>
          <ion-item lines="full">
            <ion-input
              v-model="profile.lastName" label="Last Name" label-placement="stacked"
              autocomplete="family-name"
            />
          </ion-item>
          <ion-item lines="none">
            <phone-input
              v-model="profile.phone"
              label="Phone number"
              error-text="Unverified phone number"
              :input-class="{'ion-invalid ion-touched': v$.phone.verified.$invalid}"
              @update:model-value="unVerifyPhone"
            />
            <ion-spinner v-if="verificationStatusLoading" slot="end" />
            <ion-button
              v-if="v$.phone.verified.$invalid && (profile.phone?.length == 17) && !verificationStatusLoading" slot="end" fill="solid"
              color="warning" size="default" @click="verifyPhone"
            >
              Verify
            </ion-button>
          </ion-item>
          <template v-if="profile.type == 'student'">
            <ion-item
              lines="full" :button="true"
              @click="selectHomeClub"
            >
              <ion-label position="stacked">Home Golf Club</ion-label>
              <span v-if="profile.homeClub">{{ profile.homeClub.name }}</span>
              <span v-if="!profile.homeClub">
                <ion-button fill="outline" size="small" color="medium">
                  Select
                </ion-button>
              </span>
            </ion-item>
            <ion-item v-if="profile.homeClub" lines="full">
              <ion-input
                v-model="profile.homeClubMembershipNumber" label="Membership Number (required)" label-placement="stacked"
                error-text="Please enter your membership number"
                :class="{'ion-invalid ion-touched': v$.homeClubMembershipNumber.$invalid}"
              />
            </ion-item>
          </template>
          <template v-if="profile.type == 'instructor'">
            <ion-item lines="full" class="ion-margin-vertical" style="overflow:visible">
              <ion-textarea
                v-model="profile.specializations" label="Specializations (displayed on instructor listing)" placeholder="Specify your areas of expertise and specializations"
                auto-grow="true" label-placement="stacked" rows="3"
                mode="md" fill="outline" counter="true"
                maxlength="100" autocapitalize="on"
              />
            </ion-item>
            <ion-item lines="none" class="ion-margin-vertical" style="overflow:visible">
              <ion-textarea
                v-model="profile.about" label="About Me"
                placeholder="Tell others about yourself! You can use:
  - Bold: **text**
  - Italic: *text*
  - Lists: Start lines with - or *
  - Paragraphs: Double space between them"
                auto-grow="true" label-placement="stacked" rows="6"
                mode="md" fill="outline" counter="true"
                maxlength="500" autocapitalize="on"
              />
            </ion-item>
            <template v-if="profile.paymentMethods">
              <ion-item lines="none">
                <ion-label class="ion-no-margin"><strong>Payment Methods</strong></ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-checkbox v-model="profile.paymentMethods.creditCard" label-placement="end" justify="start">
                  <ion-label style="white-space: normal">
                    Online Payments via Credit Cards
                    <br>
                    <ion-note>A golfer must pay for a lesson upon booking. You will be paid by SwingMatch bi-weekly.</ion-note>
                  </ion-label>
                </ion-checkbox>
              </ion-item>
              <ion-item lines="none">
                <ion-checkbox v-model="profile.paymentMethods.offline" label-placement="end" justify="start">
                  <ion-label style="white-space: normal">
                    Offline Payments (Cash, Membership, etc.)
                    <br>
                    <ion-note>A golfer won't be required to pay upon booking. You will accept payments offline.</ion-note>
                  </ion-label>
                </ion-checkbox>
              </ion-item>
              <ion-item
                v-if="profile.paymentMethods.offline" lines="none" style="overflow:visible"
                class="ion-margin-top"
              >
                <ion-textarea
                  v-model="profile.paymentComment" label="Additional Reminder Text (optional)" placeholder="For example, &quot;send e-transfer to tiger@woods.ca&quot;"
                  auto-grow="true" label-placement="stacked" rows="2"
                  mode="md" fill="outline" counter="true"
                  maxlength="100" autocapitalize="on"
                />
              </ion-item>
            </template>
            <!-- <ion-item lines="none">
                <ion-label color="danger">
                  <h1>Unverified Account</h1>
                  <ion-note>
                    Your account will be activated after manual verification within 2-3 days.
                    Your schedule won't be searchable until account activation.
                  </ion-note>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-checkbox
                  v-model="profile.certifiedPgaProfessional" label-placement="end" justify="start"
                  disabled=""
                >
                  <ion-label>Active Account</ion-label>
                </ion-checkbox>
              </ion-item>
              <ion-item lines="none">
                <ion-checkbox
                  v-model="profile.certifiedPgaProfessional" label-placement="end" justify="start"
                  disabled=""
                >
                  <ion-label>Certified PGA Professional</ion-label>
                </ion-checkbox>
              </ion-item> -->
          </template>
        </ion-list>
        <ion-text v-if="v$.$errors.length" color="danger">
          <ul data-testid="error-messages">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-for="err in v$.$errors" :key="err.$uid" v-html="err.$message" />
          </ul>
        </ion-text>
        <ion-button
          color="success" expand="block" class="ion-margin-top"
          :disabled="v$.$invalid" data-testid="save-profile" @click="saveProfile(true)"
        >
          {{ profile.onboarded ? 'Save Changes' : 'Activate Account' }}
        </ion-button>
        <br>
        <ion-button
          color="primary" expand="block"
          fill="outline" size="default" @click="signOutUser()"
        >
          Sign Out
        </ion-button>
      </template>
      <template v-if="profile?.onboarded">
        <calendar-feed-block />
      </template>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonButton, IonCheckbox, IonContent, IonIcon, IonInput,
  IonItem, IonLabel, IonList, IonNote, IonPage, IonSegment,
  IonSegmentButton, IonSpinner, IonText, IonTextarea, alertController, modalController, useIonRouter,
} from '@ionic/vue'
import { golfOutline, schoolOutline } from 'ionicons/icons'
import { useDocument, useFirebaseAuth } from 'vuefire'
import { helpers } from '@vuelidate/validators'
import { ref } from 'vue'
import { signOut } from 'firebase/auth'
import { useVuelidate } from '@vuelidate/core'

import { verifyPhoneCheck, verifyPhoneInit } from '@/firebase'
import AvatarUpload from '@/views/account/AvatarUpload.vue'
import CalendarFeedBlock from '@/3_widgets/homepage/CalendarFeedBlock.vue'
import PhoneInput from '@/components/atoms/PhoneInput.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { trackEvent } from '@/globals'
import { useUserStore } from '@/stores/user'

const router = useIonRouter()
const _userFromStore = useUserStore()
const { data: profile, promise: profilePromise } = useDocument(_userFromStore.userDocRef)

const verificationStatus = ref(false)
const verificationStatusLoading = ref(false)
profilePromise.value.then(() => {
  if (!profile.value) return
  if (!profile.value.paymentMethods) {
    profile.value.paymentMethods ||= {
      creditCard: true,
      offline: false,
    }
    if (profile.value.onboarded) {
      // update older onboarded profiles with default payment methods
      saveProfile()
    }
  }
  verificationStatus.value = !!profile.value.phone
})

async function verifyPhone() {
  verificationStatusLoading.value = true
  await verifyPhoneInit({ to: profile.value.phone })
  verificationStatusLoading.value = false
  const alert = await alertController.create({
    header: 'Enter Verification Code',
    message: 'Verification code has been sent to your phone. Please enter the code below.',
    backdropDismiss: false,
    inputs: [{
      name: 'code',
      type: 'number',
      placeholder: 'Verification Code (4 digits)',
    }],
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked')
        },
      },
      {
        text: 'Verify Phone',
        role: 'check',
        handler: async (values) => {
          const verificationCode = values.code
          const response = await verifyPhoneCheck({ to: profile.value.phone, code: verificationCode })
          const status = response.data.status
          if (status === 'approved') {
            verificationStatus.value = true
            await saveProfile()
            return true
          } else if (status === 'expired') {
            window.alert('Expired verification code. Please try again.')
          } else if (status === 'max_attempts_reached') {
            window.alert('Maximum attempts reached. Please wait before resending a code.')
          } else {
            window.alert('Invalid verification code. Please try again or request a new code.')
          }
          return false
        },
      }],
  })

  await alert.present()
}
async function unVerifyPhone() {
  verificationStatus.value = false
}

const rules = {
  firstName: {
    required: helpers.withMessage('First Name is required', value => !!value),
  },
  lastName: {
    required: helpers.withMessage('Last Name is required', value => !!value),
  },
  phone: {
    verified: helpers.withMessage('Please enter and verify a phone number', value => value && verificationStatus.value),
  },
  paymentMethods: {
    required: helpers.withMessage('Please select at least one payment method', value => Object.values(value).some(v => v === true)),
  },
  homeClubMembershipNumber: {
    required: helpers.withMessage('Membership Number is required', value => !profile.value.homeClub || !!value),
  },
}
const v$ = useVuelidate(rules, profile)
v$.value.$touch()

const tempProfile = ref()

function updateAvatar(url) {
  trackEvent('userinfo_avatar_updated')
  profile.value.photoURL = url
  saveProfile()
}

async function saveProfile(finishOnboarding = false) {
  let userOnboarded = false
  if (profile.value) {
    if (!profile.value.onboarded && finishOnboarding) {
      profile.value.onboarded = true
      userOnboarded = true
    }
    profile.value.name = `${profile.value.firstName} ${profile.value.lastName}`
    await sendRequest('Updating Profile...', async () => {
      await _userFromStore.fn.updateProfile(profile.value)
    }, profile.value)
    if (userOnboarded) {
      if (profile.value.type === 'instructor') {
        router.push({ name: 'schedules' })
        setTimeout(() => alert('Welcome to SwingMatch! 🏌️‍♂️ You can now create lesson schedules.'), 200)
      } else {
        const savedInstructorId = sessionStorage.getItem('instructorId')
        if (savedInstructorId) {
          router.push({ name: 'instructor', params: { instructorId: savedInstructorId }})
          _userFromStore.student.fn.handleStudentWithSavedInstructorSignUp(savedInstructorId)
        } else {
          router.push({ name: 'newLesson' })
        }
        setTimeout(() => alert('Welcome to SwingMatch! 🏌️‍♂️ You can now book lessons.'), 200)
      }
    }
  }
}

function selectProfileType() {
  profile.value.type = tempProfile.value
  saveProfile()
}

const auth = useFirebaseAuth()
function signOutUser() {
  signOut(auth).then(() => {
    window.location = '/'
  })
}

import { EDMONTON_LOCATIONS as LOCATIONS } from '@/data/location'
import LocationsSelector from '@/components/LocationsSelector.vue'

async function selectHomeClub() {
  const modal = await modalController.create({
    component: LocationsSelector,
    componentProps: {
      items: LOCATIONS,
      selectedItems: [profile.value.homeClub],
      mode: 'single',
      title: 'Select Your Home Golf Club',
    },
  })
  await modal.present()

  const { data } = await modal.onDidDismiss()
  if (data) profile.value.homeClub = data[0] || null
}
</script>
