<template>
  <!-- {{ schedule }} -->
  <!-- <span v-if="mode == 'weekly'">Weekly - {{ validityDatesFormatted(schedule) }}:</span>
    <span v-if="mode == 'dates'">Availability:</span> -->

  <template v-if="timetableEntries.length == 0">
    <ion-icon :icon="warningOutline" color="danger" />
    <ion-label color="danger">&nbsp;No timeslots set</ion-label>
  </template>
  <ion-grid v-if="timetableEntries.length > 0" class="ion-margin-top">
    <ion-row v-for="day in timetableEntries" :key="day" class="schedule-dates">
      <ion-col size="5"><span>{{ day.when }}</span></ion-col>
      <ion-col>{{ day.times.join(', ') }}</ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/vue'
import { computed, ref } from 'vue'
import { formatDate, parseISO } from 'date-fns'
import { formatTime } from '@/6_shared/utils/formattingLessons'
import { warningOutline } from 'ionicons/icons'

const props = defineProps({
  mode: { required: true, type: String, validator: value => ['weekly', 'dates'].includes(value) },
  lessonType: { required: true, type: Object },
})

const schedule = ref(props.lessonType.schedule[props.mode])

// function validityDatesFormatted(schedule) {
//   return `${useDateFormat(schedule.validity.from, 'MMM D').value}-${useDateFormat(schedule.validity.to, 'MMM D').value}`
// }

const today = formatDate(new Date(), 'yyyy-MM-dd')
const timetableEntries = computed(() => {
  return schedule.value.days
    .filter(day => day.when >= today && day.times.length > 0) // only show future dates with times
    .map((day) => {
      return {
        when: /^\d{4}-\d{2}-\d{2}$/.test(day.when) ? formatDate(parseISO(day.when), 'EEE MMM d') : day.when,
        times: day.times.map(time => formatTime(time),
        ),
      }
    })
})
</script>

<style scoped>
p {
  margin-top: 0;
  margin-bottom: 0.75em;
}
ion-row.schedule-dates {
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
  border-bottom: 1px solid var(--ion-color-light, #f4f5f8);
}
ion-row.schedule-dates:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
</style>
