<template>
  <div v-if="currentUser !== 'instructor' && !allAvailable" data-testid="booking-priority-summary-for-students">
    <ul style="padding-left: 1.1em;">
      <li v-if="currentStudentRole === 'public'">
        <ion-note :color="settings?.public?.availability === 'never' ? 'tertiary' : ''">
          <span v-if="settings?.public?.availability === 'days'"> General public can book <strong>{{ settings?.public?.days }} days</strong> ahead. </span>
          <span v-if="settings?.public?.availability === 'never'"> General public can't book any timeslots. </span>
        </ion-note>
        <ion-note>Add/Change your home course in your profile for more booking availability.</ion-note>
      </li>
      <li v-if="currentStudentRole === 'courseMember'">
        <ion-note>
          <span v-if="settings?.courseMembers?.courses?.length > 0">
            {{ settings?.courseMembers?.courses?.map(c => `"${c.name}"`).join(', ') }} members can book <strong>{{ settings?.courseMembers?.days }} days</strong> ahead
          </span>
        </ion-note>
      </li>
      <li v-if="currentStudentRole === 'vip'">
        <ion-note v-if="settings?.vip?.enabled">
          Instructor marked you as VIP. VIPs can book <strong>{{ settings?.vip?.days }} days</strong> ahead
        </ion-note>
        <ion-note v-else>
          Instructor marked you as VIP. VIPs can book all timeslots
        </ion-note>
      </li>
    </ul>
  </div>

  <div v-if="currentUser === 'instructor'" data-testid="booking-priority-summary">
    <ul v-if="!allAvailable" style="padding-left: 1.1em;">
      <li>
        <ion-note v-if="settings?.vip?.enabled">
          VIPs can book <strong>{{ settings?.vip?.days }} days</strong> ahead
        </ion-note>
        <ion-note v-else>
          VIPs can book all timeslots
        </ion-note>
      </li>
      <template v-if="settings?.courseMembers?.enabled">
        <li v-if="settings?.courseMembers?.courses?.length == 0"><ion-note color="danger">Select courses to set up priority access</ion-note></li>
        <li v-if="settings?.courseMembers?.courses?.length > 0">
          <ion-note>
            {{ settings?.courseMembers?.courses?.map(c => `"${c.name}"`).join(', ') }}
            members can book <strong>{{ settings?.courseMembers?.days }} days</strong> ahead
          </ion-note>
        </li>
      </template>
      <li>
        <ion-note v-if="settings?.public?.availability === 'days'">
          General public can book <strong>{{ settings?.public?.days }} days</strong> ahead
        </ion-note>
        <ion-note v-if="settings?.public?.availability === 'never'" color="tertiary">
          General public can't book any timeslots
        </ion-note>
      </li>
    </ul>
    <p v-if="allAvailable && currentUser === 'instructor'">
      <ion-note>All timeslots are available for everyone.</ion-note>
    </p>
  </div>
</template>

<script setup>
import { IonNote } from '@ionic/vue'
import { computed } from 'vue'

const props = defineProps({
  settings: {
    type: Object,
    default: undefined,
    required: false,
  },
  currentUser: {
    type: String,
    default: 'instructor',
    required: false,
  },
  currentStudentRole: {
    type: String,
    default: undefined,
    required: false,
  },
})

const allAvailable = computed(() => {
  return !props.settings || (
    !props.settings?.vip?.enabled
    && !props.settings?.courseMembers?.enabled
    && props.settings?.public?.availability !== 'days'
    && props.settings?.public?.availability !== 'never'
  )
})
</script>
