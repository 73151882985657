<template>
  <ion-page data-testid="lesson-page">
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="lesson" data-testid="lesson-status">
          <lesson-status :lesson="lesson" />
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" data-testid="lesson-back-button" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" fullscreen="true">
      <error-message v-if="errorMsg" :title="errorMsg" />
      <template v-if="lesson">
        <lesson-card :lesson="lesson" :current-user="userStore.is.type" />
        <ion-item
          v-if="userStore.is.type === 'instructor' && lesson.partOfPackage()" detail="true" button="true"
          lines="none" color="light"
          :router-link="{ name: 'package', params: { packageId: lesson.paymentInfo.metadata.packageId } }"
        >
          <ion-label>
            <span v-if="lesson.paymentInfo.metadata?.packageLessons && lesson.paymentInfo.metadata?.packageLessonsLeft">
              {{ lesson.paymentInfo.metadata?.packageLessons }}
              {{ lesson.paymentInfo.metadata?.packageName }} Lessons Package
            </span>
            <span v-else>
              Part of a Lessons Package
            </span>
          </ion-label>
        </ion-item>

        <lesson-notes :lesson="lesson" :current-user-type="userStore.is.type" @update:notes="updateLessonNotes" />
        <payment-info :lesson="lesson" :current-user="userStore.is.type" />
        <events-list :lesson="lesson" />
      </template>
    </ion-content>
    <lesson-actions
      v-if="lesson"
      :lesson="lesson" :current-user-type="userStore.is.type"
      @lesson:confirm="confirmLesson"
      @lesson:decline="declineLesson($event)"
      @lesson:cancel="cancelLesson"
    />
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButtons, IonContent, IonHeader,
  IonItem, IonLabel, IonPage, IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed } from 'vue'

import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import EventsList from '@/shared/components/EventsList.vue'
import LessonActions from '@/views/golfers/lessons/LessonActions.vue'
import LessonCard from '@/views/golfers/lessons/LessonCard.vue'
import LessonNotes from '@/shared/pages/LessonPage/LessonNotes.vue'
import LessonStatus from '@/4_features/LessonStatus.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import PaymentInfo from '@/3_widgets/lessons/PaymentInfo.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  lessonId: { type: String, required: true },
})

const userStore = useUserStore()
const lessonsRepo = new LessonsRepository()

const lesson = lessonsRepo.get(props.lessonId)

const errorMsg = computed(() => {
  return lesson.error?.value?.code === 'permission-denied'
    ? 'You do not have permission to access this lesson'
    : ''
})

async function confirmLesson() {
  await sendRequest('Confirming Lesson Request...', async () => {
    const result = (await lessonsRepo.confirm(lesson.value)).data
    if (result.status !== 'success') {
      alert(result.error)
    }
  }, lesson.value)
}

async function declineLesson(reason) {
  await sendRequest('Declining Lesson Request...', async () => {
    lesson.value.declineReason = reason
    await lessonsRepo.decline(lesson.value, reason)
  }, lesson.value)
}

async function cancelLesson(cancellationReason) {
  await sendRequest('Cancelling Lesson...', async () => {
    await lessonsRepo.cancelLesson(lesson.value, cancellationReason)
  }, lesson.value)
}

async function updateLessonNotes(instructorNotes) {
  await sendRequest('Updating lesson notes...', async () => {
    await lessonsRepo.updateLessonNotes({ lesson: lesson.value, instructorNotes })
  }, lesson.value)
}
</script>
