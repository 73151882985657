<template>
  <ion-avatar :style="`height:${size}px;width:${size}px;`">
    <template v-if="url">
      <img
        :src="imageUrl"
        data-testid="user-avatar"
        :data-thumbnail-url="thumbnailUrl"
        @error="useOriginalUrl"
      >
    </template>
    <template v-else-if="name">
      <div
        class="initials-avatar"
        :style="`height:${size}px;width:${size}px;font-size:${size/2.5}px`"
        data-testid="user-avatar-initials"
      >
        {{ getInitials }}
      </div>
    </template>
    <template v-else>
      <img src="/images/user_no_photo.png" data-testid="user-avatar">
    </template>
  </ion-avatar>
</template>

<script setup>
import { computed, ref } from 'vue'
import { IonAvatar } from '@ionic/vue'

const props = defineProps({
  url: { type: String, default: '' },
  size: { default: 70, type: Number },
  name: { type: String, default: '' },
})

// Start with thumbnail URL, fallback to original if needed
const imageUrl = ref('')
const thumbnailUrl = ref('')

// Set initial URL
if (props.url) {
  thumbnailUrl.value = props.url.replace(
    'public%2Fuser_photos%2F',
    'public%2Fuser_photos%2Fthumbnails%2F',
  ).replace(
    '?alt=media&token=',
    '_200x200?alt=media&token=',
  )
  imageUrl.value = thumbnailUrl.value
}

// Simple error handler that switches to original URL
const useOriginalUrl = () => {
  imageUrl.value = props.url
  console.info('Thumbnail not found, using original url')
}

const getInitials = computed(() => {
  return props.name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('')
})
</script>

<style scoped>
.initials-avatar {
  background-color: #e0e0e0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
}
</style>
