<template>
  <ion-page data-testid="new-student-page">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ isNew ? 'New Offline Student' : 'Edit Student' }}
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'students'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <ion-list v-if="student">
        <ion-item lines="none" style="overflow:visible" class="ion-margin-top">
          <ion-input
            v-model="student.name" type="text" mode="md"
            fill="outline" label="Name" label-placement="stacked"
            error-text="Name is required"
            required="true" :class="{'ion-invalid ion-touched': v$.name.$invalid, 'ion-valid': !v$.name.$invalid}"
            :disabled="!isOfflineStudent"
          />
        </ion-item>
        <ion-item
          v-if="isOfflineStudent" lines="none" style="overflow:visible"
          class="ion-margin-top"
        >
          <ion-input
            v-model="student.email" type="email" mode="md"
            fill="outline" label="Email (optional)" label-placement="stacked"
            helper-text="Notifications will be sent to this email"
            error-text="Invalid Email"
            :class="{'ion-invalid ion-touched': v$.email.$invalid, 'ion-valid': !v$.email.$invalid}"
          />
        </ion-item>
        <ion-item
          v-if="isOfflineStudent" lines="none" style="overflow:visible"
          class="ion-margin-top"
        >
          <phone-input
            v-model="student.phone"
            label="Phone Number (optional)"
            helper-text="Notifications will be sent to this phone number"
          />
        </ion-item>
        <ion-item lines="none">
          <ion-checkbox
            :checked="student.tags?.includes('vip')" justify="start" label-placement="end"
            @ion-change="toggleVIP"
          >
            VIP student (priority booking)&nbsp;
          </ion-checkbox>
        </ion-item>
        <ion-item lines="none" style="overflow:visible" class="ion-margin-top">
          <ion-textarea
            v-model="student.notes" mode="md" auto-grow="true"
            rows="2" fill="outline" label="Notes (optional)"
            label-placement="stacked" helper-text="These notes will not be visible to the golfer" autocapitalize="on"
          />
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-text v-if="v$.$errors.length" color="danger">
          <ul data-testid="error-messages">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-for="err in v$.$errors" :key="err.$uid" v-html="err.$message" />
          </ul>
        </ion-text>
        <ion-grid>
          <ion-row>
            <ion-col v-if="!isNew && isOfflineStudent">
              <ion-button
                expand="block" fill="outline" color="danger"
                data-testid="delete-student-button" @click="deleteStudent"
              >
                Delete
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block" :disabled="v$.$invalid" data-testid="save-student-button"
                fill="solid" color="success" @click="updateStudent"
              >
                Save
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader,
  IonInput, IonItem, IonList, IonPage, IonRow, IonText, IonTextarea,
  IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed, ref } from 'vue'
import { email, helpers } from '@vuelidate/validators'
import PhoneInput from '@/components/atoms/PhoneInput.vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'

import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const router = useRouter()

const props = defineProps({
  studentId: {
    type: String,
    required: false,
    default: null,
  },
})

let student

if (props.studentId) {
  student = userStore.instructor.fn.getStudent(props.studentId)
  const { data: overrideStudentData, promise: overridePromise } = userStore.instructor.fn.getOfflineStudent(props.studentId)
  overridePromise.value.then(() => {
    console.log('overrideStudentData', overrideStudentData.value)
    if (!isOfflineStudent.value) {
      student.value = {
        id: props.studentId,
        ...student.value,
        ...overrideStudentData.value,
      }
    }
  })
} else {
  student = ref({})
}

const rules = {
  name: {
    required: helpers.withMessage('Name is required', value => !!value),
  },
  email: {
    email: helpers.withMessage('Invalid email', email),
  },
}

const isNew = computed(() => !props.studentId)
const isOfflineStudent = computed(() => isNew.value || student.value?.id?.startsWith('offline_'))
const v$ = useVuelidate(rules, student)
v$.value.$touch()

async function updateStudent() {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  let dataToSave = student.value
  if (!isOfflineStudent.value) {
    dataToSave = {
      notes: student.value.notes || '',
      tags: student.value.tags || [],
    }
  }
  await sendRequest('Saving Student...', async () => {
    await userStore.instructor.fn.updateOfflineStudent(student.value.id, dataToSave)
  }, dataToSave)
  router.replace({ name: 'student', params: { studentId: student.value.id }})
}

async function deleteStudent() {
  const confirmed = window.confirm('Are you sure you want to delete this student?')
  if (!confirmed) return

  await sendRequest('Deleting Student...', async () => {
    await userStore.instructor.fn.deleteOfflineStudent(student.value.id)
  }, student.value)
  router.push({ name: 'students' })
}

function toggleVIP(event) {
  student.value.tags ||= []
  if (event.detail.checked) {
    student.value.tags.push('vip')
  } else {
    student.value.tags = student.value.tags.filter(tag => tag !== 'vip')
  }
}
</script>
