<template>
  <div>
    <ion-button
      fill="outline" expand="block" color="dark"
      class="ion-margin-bottom" @click="emailModalRef.$el.present()"
    >
      <ion-icon slot="start" :icon="mail" />
      <ion-label>Email</ion-label>
    </ion-button>
    <ion-modal id="email-modal" ref="emailModalRef">
      <form
        v-if="credentials.mode != ''" data-testid="email-login-form" class="ion-padding"
        style="background-color: var(--ion-color-light);"
      >
        <h2 style="margin-top: 0">Continue with Email</h2>
        <ion-segment v-model="credentials.mode">
          <ion-segment-button :value="AUTH_MODES.SIGN_IN">
            <ion-label>Sign In</ion-label>
          </ion-segment-button>
          <ion-segment-button :value="AUTH_MODES.SIGN_UP">
            <ion-label>Sign Up</ion-label>
          </ion-segment-button>
        </ion-segment>
        <ion-list>
          <ion-item lines="full">
            <ion-input
              v-model="credentials.email" type="email" placeholder=""
              label="Email" autocomplete="email" required
              name="email" label-placement="stacked"
              v-bind="attributesForInput('email')"
              @ion-input="onInput('email')"
            />
          </ion-item>
          <ion-item lines="full">
            <ion-input
              v-model="credentials.password" :type="passwordHidden ? 'password' : 'text'" placeholder=""
              label="Password" :autocomplete="credentials.mode == AUTH_MODES.SIGN_UP ? 'new-password' : 'current-password'" required
              name="password" label-placement="stacked"
              v-bind="attributesForInput('password')"
              @ion-input="onInput('password')"
            >
              <ion-button
                slot="end" fill="clear" class="ion-no-margin"
                @click="passwordHidden = !passwordHidden"
              >
                <ion-icon slot="icon-only" :icon="passwordHidden ? eyeOff : eye" aria-hidden="true" />
              </ion-button>
            </ion-input>
          </ion-item>
          <ion-item v-if="credentials.mode == AUTH_MODES.SIGN_UP" lines="none">
            <ion-input
              v-model="credentials.passwordConfirmation"
              :type="passwordHidden ? 'password' : 'text'" placeholder=""
              label="Password Confirmation" autocomplete="new-password" required
              name="password-confirmation" label-placement="stacked"
              v-bind="attributesForInput('passwordConfirmation')"
              @ion-input="onInput('passwordConfirmation')"
            />
          </ion-item>
        </ion-list>
        <p v-if="errorMsg" data-testid="errors">
          <ion-text color="danger">{{ errorMsg }}</ion-text>
        </p>
        <ion-button
          expand="block" class="ion-margin-top"
          color="primary" fill="solid"
          @click="continueWithEmail"
        >
          <ion-icon slot="start" :icon="mailOutline" />
          <ion-label>{{ credentials.mode == AUTH_MODES.SIGN_IN ? 'Sign In' : 'Sign Up' }}</ion-label>
        </ion-button>
        <p v-if="credentials.mode == AUTH_MODES.SIGN_IN" class="ion-text-center" style="margin-bottom: 0">
          <ion-text>Forgot Password? <strong><a style="cursor: pointer" @click="resetPasswordModalRef.$el.present()">Reset</a></strong></ion-text>
        </p>
        <ion-modal id="reset-password-modal" ref="resetPasswordModalRef">
          <reset-password-modal @reset="resetPasswordModalRef.$el.dismiss()" />
        </ion-modal>
      </form>
    </ion-modal>
    <!-- Dev-only component for quick login testing -->
    <dev-test-buttons @test-login="performTestLogin" />
  </div>
</template>

<script setup>
import {
  IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal,
  IonSegment, IonSegmentButton, IonText,
} from '@ionic/vue'
import { computed, reactive, ref } from 'vue'
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth'
import { email, helpers, required } from '@vuelidate/validators'
import { eye, eyeOff, mail, mailOutline } from 'ionicons/icons'
import { useFirebaseAuth } from 'vuefire'
import { useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'

import DevTestButtons from './SignInBlock/DevTestButtons.vue'
import ResetPasswordModal from './SignInBlock/ResetPasswordModal.vue'
import { SM } from '@/globals'
import { testLoginAs } from '@/firebase'

const route = useRoute()

const AUTH_MODES = {
  SIGN_IN: 'signin',
  SIGN_UP: 'signup',
}

const emit = defineEmits(['login-success'])

const auth = useFirebaseAuth()
const credentials = reactive({
  email: '',
  password: '',
  mode: route.query.mode || AUTH_MODES.SIGN_IN,
})
const errorMsg = ref('')
const emailModalRef = ref()
const resetPasswordModalRef = ref(null)
const passwordHidden = ref(true)

const passwordRules = {
  minLength: helpers.withMessage('Minimum 6 characters', password => password.length >= 6),
  uppercase: helpers.withMessage('Include an uppercase letter', password => /[A-Z]/.test(password)),
  lowercase: helpers.withMessage('Include a lowercase letter', password => /[a-z]/.test(password)),
  number: helpers.withMessage('Include a number', password => /\d/.test(password)),
  specialChar: helpers.withMessage('Include a special character (e.g., !@#$%^&*)', password => /[!@#$%^&*-]/.test(password)),
}

const rules = {
  email: { required, email },
  password: computed(() => ({
    required,
    ...(credentials.mode === AUTH_MODES.SIGN_IN ? {} : passwordRules),
  })),
  passwordConfirmation: {
    sameAs: helpers.withMessage('Must match password', value =>
      credentials.mode === AUTH_MODES.SIGN_IN || value === credentials.password,
    ),
  },
}

const v$ = useVuelidate(rules, credentials)

const attributesForInput = (inputName) => {
  const field = v$.value[inputName]
  return {
    'class': {
      'ion-invalid': field.$invalid,
      'ion-valid': !field.$invalid,
      'ion-touched': field.$dirty,
    },
    'error-text': field.$errors.map(e => e.$message).join(', '),
  }
}

const onInput = (inputName) => {
  v$.value[inputName].$touch()
}

async function successCallback() {
  await emailModalRef.value?.$el.dismiss()
  emit('login-success')
}

async function continueWithEmail() {
  errorMsg.value = ''
  await v$.value.$validate() // display errors on the inputs if any, otherwise they're hidden for untouched inputs
  if (v$.value.$invalid) return

  try {
    if (credentials.mode === AUTH_MODES.SIGN_IN) {
      await signInWithEmailAndPassword(auth, credentials.email, credentials.password)
    } else {
      await createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
      await sendEmailVerification(auth.currentUser)
    }
    await successCallback()
  } catch (error) {
    handleAuthError(error)
  }
}

function handleAuthError(error) {
  const errorCode = error.code
  console.error('Authentication error:', errorCode, error.message)

  if (credentials.mode === AUTH_MODES.SIGN_IN) {
    const signInErrorCodes = ['auth/invalid-credential', 'auth/user-not-found', 'auth/wrong-password']
    if (signInErrorCodes.includes(errorCode)) {
      errorMsg.value = 'User not found or wrong password. Please try again.'
    }
  } else if (errorCode === 'auth/email-already-in-use') {
    errorMsg.value = 'Email already in use. Please sign in or reset your password.'
  }
}

async function performTestLogin(email, password, additionalParams = {}) {
  if (SM.env !== 'development') {
    alert('works only in development mode')
    return
  }
  await emailModalRef.value?.$el.present()
  credentials.mode = AUTH_MODES.SIGN_IN
  credentials.email = email
  credentials.password = password
  passwordHidden.value = false
  v$.value.$touch()
  await testLoginAs({ email, password, ...additionalParams })
  setTimeout(continueWithEmail, 100)
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#email-modal {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 14px;
}
</style>
