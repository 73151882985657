<template>
  <div v-if="isInstructor" class="ion-padding-horizontal">
    <div data-testid="share-profile-block" class="ion-padding-top">
      <!-- <ion-textarea
        readonly="true" :value="profileLink" label="My Profile Link"
        label-placement="stacked" rows="2" mode="md"
        fill="outline" class="ion-margin-top"
      /> -->
      <ion-button
        color="success" expand="block" fill="outline"
        @click="shareProfileLink"
      >
        Share My Profile
      </ion-button>
      <ion-note>
        You can send this link to students to invite them to book with you. The link can be shared via Chat, Email or Text Message.
        <br>
        <a :href="profileLink.replace('https://app.swingmatch.ca/', '/')">Visit my profile as a student » </a>
      </ion-note>
    </div>
  </div>
</template>

<script setup>
import { IonButton, IonNote } from '@ionic/vue'
import { computed } from 'vue'

import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const isInstructor = computed(() => userStore.is.type === 'instructor')
const profileLink = computed(() => `https://app.swingmatch.ca/my/instructors/${userStore.userData().id}`)

async function shareProfileLink() {
  try {
    await navigator.share({ url: profileLink.value })
  } catch (err) {
    console.log(err)
  }
}
</script>
