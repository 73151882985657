<template>
  <ion-item
    detail="true" button="true" lines="full"
    @click="open({ accept: 'image/*', multiple: false })"
  >
    <ion-label position="start">Upload Photo</ion-label>
    <div class="ion-text-center ion-margin-vertical">
      <user-avatar
        slot="start" :key="currentAvatar" :url="currentAvatar"
        :size="60"
        style="border:1px solid var(--ion-color-light)"
      />
      <ion-progress-bar v-if="uploadProgress && uploadProgress != 1" :value="uploadProgress" />
    </div>
  </ion-item>
</template>

<script setup>
import { IonItem, IonLabel, IonProgressBar } from '@ionic/vue'
import { useFirebaseStorage, useStorageFile, useStorageFileUrl } from 'vuefire'
import { ref as storageRef } from 'firebase/storage'
import { useFileDialog } from '@vueuse/core'
import { watch } from 'vue'

import UserAvatar from '@/3_widgets/UserAvatar.vue'

const props = defineProps({
  uid: { type: String, required: true },
  currentAvatar: { type: String, required: false, default: null },
})

const emit = defineEmits(['update:avatar'])

const storage = useFirebaseStorage()
const avatarFileRef = storageRef(storage, `public/user_photos/${props.uid}`)

const { uploadProgress, upload } = useStorageFile(avatarFileRef)
const { url, refresh } = useStorageFileUrl(avatarFileRef)

async function uploadPicture() {
  const data = files.value?.item(0)
  if (data) {
    await upload(data)
    await refresh() // force refresh to get new url
    emit('update:avatar', url.value)
  }
}

const { files, open } = useFileDialog()

watch(files, uploadPicture)

</script>
