export const EDMONTON_LOCATIONS = [
  {
    uuid: 'cf4d054f-6b21-4e4b-9104-543f0e9ff7c6',
    city: 'Edmonton',
    type: 'public',
    name: 'Victoria Golf Course',
    address: '12130 River Valley Rd, Edmonton, AB, T5J 2G7',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '69599fb6-e7c6-4bf8-b53c-1e0d298f77c2',
    city: 'Edmonton',
    type: 'private',
    name: 'Royal Mayfair Golf Club',
    address: '9450 Groat Rd NW, Edmonton, AB, T6G 2T5',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '93c36034-7577-40d0-ba30-5d4ccbe30ad8',
    city: 'Edmonton',
    type: 'public',
    name: 'River Ridge Golf & Country Club',
    address: '5685 Windermere Blvd SW, Edmonton, AB, T6W 0S6',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'bfb76f86-0de6-493b-b446-878cff864c79',
    city: 'Edmonton',
    type: 'public',
    name: 'The Quarry Golf Club',
    address: '945-167 Avenue NE, Edmonton, AB, T5Y 0P6',
    holes: 27,
    drivingRange: true,
  },
  {
    uuid: '2b77e4ee-385d-46d3-b398-8e7df8daba67',
    city: 'Edmonton',
    type: 'public',
    name: 'Cougar Creek Golf Resort',
    address: '2519 Highway 16, Edmonton, AB, T5T 5L4',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '69027f1c-d5cc-459c-885c-e589d0090731',
    city: 'Edmonton',
    type: 'public',
    name: 'Lewis Estates Golf Course',
    address: '260 Suder Greens Dr NW, Edmonton, AB, T5T 4B7',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '0c74013b-623f-4424-8753-884447da59c1',
    city: 'Edmonton',
    type: 'public',
    name: 'Dragons Head Golf',
    address: '12240-199 St, Edmonton, AB, T5V 1T8',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '6d7f5826-747e-45c5-9c6a-ea761d60515b',
    city: 'Edmonton',
    type: 'public',
    name: 'Jagare Ridge Golf Club',
    address: '14931 Ellerslie Rd SW, Edmonton, AB, T6W 1A4',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '25ea370b-acf5-480b-93d3-c4bb31547b44',
    city: 'Edmonton',
    type: 'public',
    name: 'Riverside Golf Club',
    address: '8630 Rowland Road, Edmonton, AB, T5J 2T6',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: 'abf3ef1a-1af2-47ea-8352-b409ca6012ac',
    city: 'Edmonton',
    type: 'private',
    name: 'Glendale Golf & Country Club',
    address: '12410 199 St NW, Edmonton, AB, T5V 1T8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'a8585bfb-ec19-47cf-acb5-b4f3681f8250',
    city: 'Edmonton',
    type: 'private',
    name: 'Highlands Golf Club',
    address: '6603 Ada Blvd, Edmonton, AB, T5W 4N5',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '164dc093-ae9a-4411-b7ca-d19f2af16be8',
    city: 'Edmonton',
    type: 'private',
    name: 'Edmonton Country Club',
    address: '6201 Country Club Rd, Edmonton, AB, T6M 2J6',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '67b2eb92-9a07-4eed-b61b-f04938972794',
    city: 'Edmonton',
    type: 'private',
    name: 'Windermere Golf & Country Club',
    address: '19110 Ellerslie Road SW, Edmonton, AB, T6W 1A5',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '7c04d520-1e02-4af7-aac8-1dc149b9e2b2',
    city: 'Edmonton',
    type: 'public',
    name: 'Twin Willows Golf Club',
    address: '14110 156th Street NW, Edmonton, AB, T6V 1J2',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '76ca75e8-362b-47b1-a5fe-0a27e42d5ffb',
    city: 'Edmonton',
    type: 'public',
    name: 'Golden West Golf Course',
    address: '16410-137th Avenue, Edmonton, AB, T5L 4H8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'dc447019-e854-4275-b712-8d56146d948e',
    city: 'Edmonton',
    type: 'public',
    name: 'Kinsmen Park Pitch & Putt',
    address: '5408 111th Ave. St., Edmonton, AB, T5R 2T9',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: 'a9f580d3-db68-4071-903f-7443ba86e603',
    city: 'Edmonton',
    type: 'private',
    name: 'The Derrick Golf & Winter Club',
    address: '3500 119 St NW, Edmonton, AB, T6J 5P5',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'b6f93c07-59cb-4d7b-b52a-b285ae8bf128',
    city: 'Edmonton',
    type: 'public',
    name: 'Rundle Park Golf Course',
    address: '2902 118th Ave, Edmonton, AB, T5S 2M5',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '265db0b8-8183-4168-b716-4f0e0221d8c6',
    city: 'Edmonton',
    type: 'public',
    name: 'Raven Crest Golf & Country Club',
    address: '251 153 Ave, Edmonton, AB, T5Y 6K8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '6e8f66c4-336d-4682-9a17-362ebd14b2c9',
    city: 'Edmonton',
    type: 'public',
    name: 'Pioneer Meadows Golf Course',
    address: '34th Street, Edmonton, AB, T6K 4C2',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'c1cf507c-7644-4427-b217-88046ebb4554',
    city: 'Edmonton',
    type: 'public',
    name: 'Garrison Memorial Golf & Curling Club',
    address: '298 Falaise Avenue, Edmonton, AB, T0A 2H0',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'd7731de3-e8b7-45ec-9091-f756a592e191',
    city: 'Edmonton',
    type: 'public',
    name: 'Mill Woods Golf Club',
    address: '4540-50th Street, Edmonton, AB, T6L 6P6',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'e6dda9a9-de50-4edd-9db5-116c33d3952e',
    city: 'Edmonton',
    type: 'private',
    name: 'Petroleum Golf & Country Club',
    address: '215 St and 9 Ave, Edmonton, AB, T7Y 1B1',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '23a5fa39-abc2-4d93-bd4d-aba98d8f316b',
    city: 'Edmonton',
    type: 'public',
    name: 'Montgomery Glen Golf & Country Club',
    address: 'AB-13 & AB-2A & Township Rd 464, Edmonton, AB, T5G 0X7',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '16d07f60-863f-4227-9121-174900e934b4',
    city: 'Edmonton',
    type: 'public',
    name: 'Rich Valley Resort Golf Course & RV Park',
    address: '204, 10123 157 Street, Edmonton, AB, T5P 2T9',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: '9f025ca5-a5e9-4bd2-aabd-419f0d61b238',
    city: 'Edmonton',
    type: 'public',
    name: 'Seven Oaks Golf Course',
    address: '11310-30 Avenue SW, Edmonton, AB, T0B 4C0',
    holes: 9,
    drivingRange: true,
  },
  {
    uuid: 'cd8a1b6f-e4fd-4dd9-8999-9a2202d29553',
    city: 'Edmonton',
    type: 'public',
    name: 'Sandpiper Golf & Country Club',
    address: '26029 Meadowview Dr, Sturgeon County, AB, T8T 0K8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'c006ac85-0473-454c-bc2c-9a14e2084b86',
    city: 'Edmonton',
    type: 'public',
    name: 'Eagle Rock Golf Course',
    address: '50549 - RR 234, Luduc County, AB, T4X 0L7',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '81da0d8f-0703-4521-959c-587db1c42ab0',
    city: 'Edmonton',
    type: 'public',
    name: 'Meadowview Golf & Country Club',
    address: '26329 Meadowview Dr, Sturgeon County, AB, T8T 0L7',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: 'd14af8c4-98a6-4699-93f9-9214d4c62a4f',
    city: 'Edmonton',
    type: 'private',
    name: 'Belvedere Golf & Country Club',
    address: '51418 Hwy 21, Sherwood Park, AB, T8H 2T2',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '83feb150-3065-4894-b00c-bb0ae88c48b7',
    city: 'Edmonton',
    type: 'public',
    name: 'The Broadmoor Golf Course',
    address: '2100 Oak St, Sherwood Park, AB, T8A 0V9',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '3383e230-8b67-4cd4-bd30-8160b60bf46a',
    city: 'Edmonton',
    type: 'public',
    name: 'Sturgeon Valley Golf & Country Club',
    address: '25114 Sturgeon Rd, Sturgeon County, AB, T8T 1S6',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '4dcf50a7-cdc6-4f48-b694-aa7bcbe78d13',
    city: 'Edmonton',
    type: 'public',
    name: 'Terrae Pines Golf & Country Club',
    address: 'Suite 403, 3-11 Bellerose Drive, St. Albert, AB, T8N 5C9',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'ff8d7b30-aec4-4bff-8791-137930eb3657',
    city: 'Edmonton',
    type: 'public',
    name: 'Coyote Crossing',
    address: '52321 Range Road 233, Sherwood Park, AB, T8B 1C8',
    holes: 9,
    drivingRange: true,
  },
  {
    uuid: 'cc41b169-776c-4534-bd30-ddd8cad6db3e',
    city: 'Edmonton',
    type: 'public',
    name: 'Northern Bear Golf Club',
    address: '51055 Range Road 222, Sherwood Park, AB, T8C 1G9',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '50b6ea62-98ce-42b3-8861-7de164fa817d',
    city: 'Edmonton',
    type: 'public',
    name: 'Country Side Golf Club',
    address: '51466 Range Road 232, Sherwood Park, AB, T8B 1L1',
    holes: 27,
    drivingRange: true,
  },
  {
    uuid: '24e48b1b-6ddd-4077-b6f8-071c9999353e',
    city: 'Edmonton',
    type: 'public',
    name: 'The Legends Golf & Country Club',
    address: '53541 Range Road 232, Sherwood Park, AB, T8A 4V2',
    holes: 27,
    drivingRange: false,
  },
  {
    uuid: '93bb8ea2-0e18-4f2c-898d-96ec23d1fad0',
    city: 'Edmonton',
    type: 'public',
    name: 'Coloniale Golf & Country Club',
    address: '10 Country Club Drive, Beaumont, AB, T4X 1M1',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '2f94235b-46c0-4be4-80f5-f57ff60982ce',
    city: 'Edmonton',
    type: 'public',
    name: 'Westridge Park Golf Course',
    address: 'Highway 60, Devon, AB, T0C 1E0',
    holes: 9,
    drivingRange: true,
  },
  {
    uuid: '5892175c-6cf9-4b68-b103-a412a3e26105',
    city: 'Edmonton',
    type: 'public',
    name: 'Devon Golf & Conference Centre',
    address: '1130 Rivervalley, Devon, AB, T9G 1Z3',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '3097744e-369f-481b-b63b-17c5870a5a92',
    city: 'Edmonton',
    type: 'public',
    name: 'The Links At Spruce Grove',
    address: '100 Links Rd, Spruce Grove, AB, T7X 3C9',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '157e36ee-16f0-43ed-953c-855f8efba5cd',
    city: 'Edmonton',
    type: 'public',
    name: 'Fox Run Golf Course',
    address: '22336 Township Road 540, Ardrossan, AB, T8E 2L7',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '124dd6b9-5fb2-4ebf-967c-52a67cf15241',
    city: 'Edmonton',
    type: 'private',
    name: 'Blackhawk Golf Club',
    address: '51111 Range Road 255, Spruce Grove, AB, T7Y 1A8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '280b4b35-0e83-4fbc-8c6d-23ccc8556d3d',
    city: 'Edmonton',
    type: 'public',
    name: 'Springs Golf Resort',
    address: '53302 Range Rd 264 #9, Spruce Grove, AB, T7X 3H5',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '6ca10b6c-b438-4d11-b3a7-5ce0c48b1feb',
    city: 'Edmonton',
    type: 'public',
    name: 'Deer Meadows Golf Course & RV Park',
    address: '53303A Highway 44, Spruce Grove, AB, T7X 3H5',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: '9d12aa3c-bbe0-4677-adba-596020d50b19',
    city: 'Edmonton',
    type: 'public',
    name: 'The Ranch Golf & Country Club',
    address: '9574 Pinchbeck Road, Acheson, AB, T7X 6K8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '167b6fe9-6045-4dd7-840b-9f7e0c82111d',
    city: 'Edmonton',
    type: 'public',
    name: 'Fort Saskatchewan Golf & Curling Club',
    address: '9420 102 St., Fort Saskatchewan, AB, T8L 1T8',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: '90d71ba1-a0ab-4d5a-a5a6-87f7f96efce6',
    city: 'Edmonton',
    type: 'public',
    name: 'Fort in View Golf Course',
    address: '55028 - RR 230, Sturgeon County, AB, T8L 5B4',
    holes: 27,
    drivingRange: true,
  },
  {
    uuid: 'bd488838-c6ab-4d3c-9850-3bd81a7ab492',
    city: 'Edmonton',
    type: 'public',
    name: 'Elk Island Golf Course',
    address: 'Elk Island National Park, Sherwood Park, AB, T0B 2R0',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: '367ffe8d-b063-41f8-ae14-abc1e1ce71fe',
    city: 'Edmonton',
    type: 'public',
    name: 'Leduc Golf Club',
    address: '5725 Black Gold Drive, Leduc, AB, T9E 8C4',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'eaa0044b-092c-478c-8a3a-40e4c8b1f4e9',
    city: 'Edmonton',
    type: 'public',
    name: 'RedTail Landing Golf Club',
    address: 'Edmonton International Airport, Nisku, AB, T9E 8A8',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '546618f5-bbfb-4e15-8146-081f5c3b4114',
    city: 'Edmonton',
    type: 'public',
    name: 'Cardiff Golf & Country Club',
    address: '55307 RR 251, Morinville, AB, T8R 1S1',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '6205e3f9-d3e6-4c46-bb88-bcfb94f95fa1',
    city: 'Edmonton',
    type: 'public',
    name: 'Spring Creek Golf Course',
    address: '24007 Twp Rd 560, Bon Accord, AB, T0A 0K0',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '6a518e5a-7a03-4c5a-a8fa-8ec48b116e20',
    city: 'Edmonton',
    type: 'public',
    name: 'Duffy\'s Challenge',
    address: '54007 Hwy 779, Stony Plain, AB, T7X 1Y4',
    holes: 18,
    drivingRange: false,
  },
  {
    uuid: '9e728f97-85cb-477f-9fc5-9593559a0db6',
    city: 'Edmonton',
    type: 'public',
    name: 'Stony Plain Golf Course',
    address: '18 Fairway Dr., Stony Plain, AB, T7Z 1M3',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '7634e526-ebe5-44ca-a64f-46d597bd7afc',
    city: 'Edmonton',
    type: 'public',
    name: 'Goose Hummock Golf Resort',
    address: '23210, Township Road 564, Gibbons, AB, T0A 1N0',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '77a7b4e3-2288-40b9-9532-4761e6ea70e7',
    city: 'Edmonton',
    type: 'public',
    name: 'Calahoo Hills Golf Course',
    address: '54410 Range Road 280, Sturgeon County, AB, T8R 1Z5',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: '07751c5e-587b-4e8a-b769-f0aaf96bf907',
    city: 'Edmonton',
    type: 'public',
    name: 'Bull Hill Golf & Driving Range',
    address: 'Joseph Lake Road, New Sarepta, AB, T0B 3M0',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: 'de82eb51-8ae3-4d3e-8681-b21be9352d32',
    city: 'Edmonton',
    type: 'public',
    name: 'Grouse Nest Golf Course',
    address: '53318 Range Road 31, Parkland County, AB, T7Y 0E3',
    holes: 9,
    drivingRange: false,
  },
  {
    uuid: '27f2ec33-c487-495f-9942-17d55f225b18',
    city: 'Calgary',
    type: 'unknown',
    name: 'Carnmoney Golf & Country Club',
    address: '44001 Dunbow Rd, De Winton, AB T1S 4X7',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '66bca2bc-cabb-4fb5-b948-01b9d7b1e515',
    city: 'Calgary',
    type: 'private',
    name: 'Cottonwood Golf & Country Club',
    address: '88008 226 Ave E #200, Foothills, AB T1S 4A6',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '5d2210a1-5c0f-4c7d-9e22-0e3f64eb7824',
    city: 'Banff',
    type: 'unknown',
    name: 'Fairmont Banff Springs Golf Course',
    address: '405 Spray Avenue, Banff, AB T1L 1J4',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '8f7e9d6c-3a2b-4e1f-9f8a-5b4c3d2a1e0f',
    city: 'Edmonton',
    type: 'unknown',
    name: 'Lone Spruce Driving Range',
    address: '25522 Secondary Hwy, 633 Villeneuve Rd, St. Albert, AB T8N 3W8',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '3f7b8c9d-1a2e-4f5g-6h7i-8j9k0l1m2n3o',
    city: 'Calgary',
    type: 'unknown',
    name: 'McKenzie Meadows Golf Club',
    address: '17215 McKenzie Meadows Dr SE, Calgary, AB T2Z 2T9',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '8f9e0d7c-6b5a-4c3d-2e1f-0a9b8c7d6e5f',
    city: 'Ponoka',
    type: 'unknown',
    name: 'Ponoka Golf Club',
    address: '3519 46 St, Ponoka, AB T4J 1R5',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: 'd251317a-60e4-4542-b371-973ab25c462c',
    city: 'Sechelt, BC',
    type: 'unknown',
    name: 'Blue Ocean Golf Club',
    address: '6177 Ripple Way, Sechelt, BC V7Z 0R2',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '5f7e6d4c-3b2a-1e9d-8c7f-0a6b5d4e3f2g',
    city: 'Radium Hot Springs',
    type: 'unknown',
    name: 'Radium Hot Springs Golf Club',
    address: '4714 Springs Dr #310, Radium Hot Springs BC V0A 1M0',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: '7f9c1d3e-5b2a-4f8d-9e6c-3a1b2d4f5e7g',
    city: 'Edmonton',
    type: 'indoor',
    name: 'The Golf Den (Indoor)',
    address: '975 Ordze Rd, Sherwood Park AB T8A 4L7',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'a8b7c6d5-e4f3-4a2b-8c9d-1e2f3a4b5c6d',
    city: 'Edmonton',
    type: 'indoor',
    name: 'Gimme Virtual Golf (Indoor)',
    address: '10846 170 St NW, Edmonton, AB, T5S 2H7',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'd1e2f3a4-b5c6-4d7e-8f9g-0h1i2j3k4l5m',
    city: 'Edmonton',
    type: 'indoor',
    name: 'The Golf Factory (Indoor)',
    address: '10457 184 St NW Edmonton, Alberta T5S 1G1',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: '1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
    city: 'Calgary',
    type: 'private',
    name: 'Willow Park Golf & Country Club',
    address: '639 Willow Park Dr SE, Calgary, AB T2J 0L6',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '3e4f5g6h-7i8j-9k0l-1m2n-3o4p5q6r7s8t',
    city: 'Edmonton',
    type: 'indoor',
    name: 'B.E FIT Space (Indoor)',
    address: '6031 Gateway Blvd NW, Edmonton AB T6H 2H3',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: '9a8b7c6d-5e4f-3g2h-1i0j-9k8l7m6n5o4p',
    city: 'Edmonton',
    type: 'indoor',
    name: 'GOLF360 (Indoor)',
    address: '6031 Andrews Way SW, Edmonton AB',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'f1e2d3c4-b5a6-4789-9876-543210fedcba',
    city: 'Calgary',
    type: 'indoor',
    name: 'Golf Town - Riocan Beacon Hill (Indoor)',
    address: '11450 Sarcee Trail NW, Calgary, AB T3R 0A1',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'a1b2c3d4-e5f6-4789-9876-123456789abc',
    city: 'Calgary',
    type: 'indoor',
    name: 'Golf Town - Sunridge Way (Indoor)',
    address: '500, 2929 Sunridge Way NE Unit 200, Calgary, AB T1Y 7K7',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'b2c3d4e5-f6g7-4789-9876-987654321def',
    city: 'Calgary',
    type: 'indoor',
    name: 'Golf Town - Shawville Blvd (Indoor)',
    address: '47 Shawville Blvd SE, Calgary, AB T2Y 3P3',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: '2d3e4f5g-6h7i-8j9k-1l2m-3n4o5p6q7r8s',
    city: 'Edmonton',
    type: 'indoor',
    name: 'Paradise Golf (Indoor)',
    address: '6109 45 St #1, Leduc, AB T9E 7C3',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: '7d8e9f0a-1b2c-3d4e-5f6g-7h8i9j0k1l2m',
    city: 'Edmonton',
    type: 'indoor',
    name: 'Aurora Golf + Bar Beaumont (Indoor)',
    address: '5015 52 Ave, Beaumont, AB T4X 1E5',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'f9e8d7c6-b5a4-4321-9876-543210abcdef',
    city: 'Calgary',
    type: 'indoor',
    name: 'Bryce Halverson Teaching Studio (Indoor)',
    address: '8217 31 St SE, Calgary AB',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'c4d3b2a1-9876-5432-1098-7654321fedcb',
    city: 'Calgary',
    type: 'public',
    name: 'The Track Golf Course',
    address: '333 Boulder Creek Dr, Langdon, AB T0J 1X3',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '8f4e7d2c-1a9b-4c5d-8e3f-2b1a9c8d7e6f',
    city: 'Edmonton',
    type: 'public',
    name: 'Cattail Crossing Golf Club',
    address: '24427 Township Road 542, Edmonton AB, T8T 1L4',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: '3a2b1c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
    city: 'Edmonton',
    type: 'public',
    name: 'Canada Golf Card Driving Range',
    address: '13510 Ellerslie Rd SW, Edmonton AB T6W 0B3',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'e7d6c5b4-a3f2-4e1d-9b8c-7d6e5f4a3b2c',
    city: 'Canmore',
    type: 'private',
    name: 'Stewart Creek Golf & Country Club',
    address: '4100 Stewart Creek Drive, Canmore AB T1W 2V3',
    holes: 0,
    drivingRange: 'true',
  },
  {
    uuid: '9d847e3a-1b2c-4d5e-8f9a-0b1c2d3e4f5g',
    city: 'Calgary',
    type: 'unknown',
    name: 'Sirocco Golf Club',
    address: '210178 64 St W, Calgary AB T1S 5G5',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: 'f1e9d8c7-b6a5-4321-9876-543210fedcba',
    city: 'Innisfail',
    type: 'unknown',
    name: 'Innisfail Golf Club',
    address: '6080 Lakewood Dr, Innisfail, AB T4G 1S7',
    holes: 18,
    drivingRange: 'unknown',
  },
  {
    uuid: '2a1b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
    city: 'Medicine Hat',
    type: 'indoor',
    name: 'Studio Fore (Indoor)',
    address: '753 6th St. S.E., Medicine Hat, AB',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'b4a3c2d1-e5f6-4789-9876-123456789def',
    city: 'Victoria',
    type: 'unknown',
    name: 'Uplands Golf Club',
    address: '3300 Cadboro Bay Rd, Victoria BC V8R 5K5',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: 'a1b2c3d4-e5f6-4789-9876-456789abcdef',
    city: 'Victoria',
    type: 'indoor',
    name: 'Iron & Wood Golf Simulators (Indoor)',
    address: '3453 Saanich Rd, VICTORIA BRITISH COLUMBIA V8X 1W6',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'c5d4e3f2-1234-5678-9abc-def012345678',
    city: 'Victoria',
    type: 'unknown',
    name: 'Highland Pacific Golf',
    address: '450 Creed Rd, Victoria BC V9B 6C9',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: 'e2d1c0b9-8a7f-6543-2109-876543210abc',
    city: 'Chilliwack',
    type: 'unknown',
    name: 'Cheam Mountain Golf Course',
    address: '44610 Luckakuck Way, Chilliwack BC V2R 4A7',
    holes: 0,
    drivingRange: 'unknown',
  },
  {
    uuid: 'f3e2d1c0-b9a8-7654-3210-987654321def',
    city: 'Duncan',
    type: 'public',
    name: 'Duncan Meadows Golf Course',
    address: '6507 N Rd, Duncan, BC V9L 5Z2',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'g4f3e2d1-c0b9-8765-4321-098765432ghi',
    city: 'Duncan',
    type: 'indoor',
    name: 'The Tee Box - Cowichan Valley Golf Simulators (Indoor)',
    address: '5869 York Rd, Duncan, BC V9L 3S3',
    holes: 0,
    drivingRange: true,
  },
  {
    uuid: 'g5f4e3d2-c1b0-9876-5432-109876543ijk',
    city: 'Vancouver',
    type: 'public',
    name: 'Tsawwassen Springs',
    address: '5133 Springs Blvd, Tsawwassen, BC V4M 0A6',
    holes: 18,
    drivingRange: true,
  },
  {
    uuid: 'h6g5f4e3-d2c1-b0a9-8765-432109876lmn',
    city: 'Online',
    type: 'online',
    name: 'Online Lesson',
    address: 'Virtual Location',
    holes: 0,
    drivingRange: true,
  },
]
