<template>
  <ion-page>
    <ion-content color="light">
      <ion-list data-testid="instructors-filters">
        <ion-item
          lines="full" :button="true" :detail="true"
          @click="selectLocation"
        >
          <ion-label v-if="!booking.location" position="fixed">Location</ion-label>
          <ion-label slot="end">
            <span v-if="booking.location.length">{{
              booking.location.length > 2 ? `Multiple locations (${booking.location.length})` : booking.location.map(location => location.name).join(', ')
            }}</span>
            <span v-if="!booking.location.length">
              All locations
            </span>
          </ion-label>
        </ion-item>
        <!-- <ion-item lines="full">
          <ion-select
            v-model="booking.city" interface="action-sheet" label="Location"
            label-placement="fixed"
            multiple="false"
          >
            <ion-select-option
              v-for="(count, city) in instructorsByCityCounts"
              :key="city"
              :value="city"
            >
              {{ city }} ({{ count }})
            </ion-select-option>
          </ion-select>
        </ion-item> -->
        <ion-item v-if="pricingTypesWithCounts.length" lines="none">
          <ion-select
            v-model="booking.pricingType" interface="action-sheet" label="Lesson Type"
            label-placement="fixed" multiple="false" :selected-text="selectedPricingTypeWithCount"
          >
            <ion-select-option
              v-for="(pricingType) in pricingTypesWithCounts"
              :key="pricingType.key"
              :value="pricingType.key"
            >
              {{ pricingType.name }} ({{ pricingType.count }})
            </ion-select-option>
          </ion-select>
        </ion-item>

        <!-- <ion-item>
          <ion-select
            v-model="when" interface="popover" label="When"
            disabled="true"
          >
            <ion-select-option value="any_time">Any Time</ion-select-option>
            <ion-select-option value="specific_days">Specific Days</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-show="when == 'specific_days'">
          <ion-datetime
            presentation="date" :multiple="true" :value="['2022-06-03', '2022-06-13', '2022-06-29']"
          />
        </ion-item> -->
      </ion-list>
      <br>
      <ion-spinner v-if="isLoadingAvailableInstructors" />
      <error-message
        v-if="sortedInstructors.length == 0 && !isLoadingAvailableInstructors"
        :title="`No ${ALL_PRICING_TYPES[booking.pricingType].name} Instructors Available`"
      />
      <transition-group v-if="sortedInstructors.length" name="slide" tag="div">
        <instructor-card
          v-for="instructor in sortedInstructors" :key="instructor.id"
          :instructor="instructor"
          :filter="{ pricingType: booking.pricingType, location: booking.city }"
          :liked="likedUsers[instructor.id]"
          @like:instructor="userStore.student.fn.likeUser($event)"
        />
      </transition-group>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonContent, IonItem, IonLabel, IonList, IonPage, IonSelect, IonSelectOption, IonSpinner, modalController,
} from '@ionic/vue'
import { computed, reactive, watch } from 'vue'
import { useCollection, useDocument } from 'vuefire'
import { useRoute, useRouter } from 'vue-router'
import { uniqBy } from 'lodash'

import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import InstructorCard from '@/views/golfers/lessons/InstructorCard.vue'
import { InstructorsConverter } from '@/5_entities/instructors/Instructor'
import { EDMONTON_LOCATIONS as LOCATIONS } from '@/data/location'
import { LessonsRepository } from '@/data/LessonsRepository'
import { useUserStore } from '@/stores/user'

const lessonsRepo = new LessonsRepository()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

// const when = ref('any_time')
const preselectedLocations = LOCATIONS.filter(location => route.query.location?.split(',')?.includes(location.uuid))
const booking = reactive({
  pricingType: 'adult',
  location: preselectedLocations,
  // city: route.query.city || 'Edmonton',
})

watch(booking, () => {
  const locationQuery = booking.location.map(l => l.uuid).join(',') || undefined
  const routeQuery = { ...route.query, location: locationQuery }
  router.replace({ query: routeQuery })
})

const { data: availableInstructors, pending: isLoadingAvailableInstructors } = useCollection(computed(() =>
  lessonsRepo.listLessonTypes().withConverter(InstructorsConverter),
), { ssrKey: 'availableInstructors' })

const filteredByCity = computed(() => {
  let result = availableInstructors.value
  if (booking.location.length) {
    result = result.filter(instructor => instructor.locations().some(location => booking.location.map(l => l.uuid).includes(location.uuid)))
  }
  // Commented out for now
  // .filter(instructor => instructor.cities().includes(booking.city))
  return result
})

const filteredByPricingType = computed(() => {
  let result = filteredByCity.value.filter(instructor => instructor.teaches(booking.pricingType))
  const ADMIN_STUDENT_EMAILS = ['hello@ildar.ca', 'chrisotto15@gmail.com', 'nickdesanko@swingmatch.ca']
  if (!ADMIN_STUDENT_EMAILS.includes(userStore.userData()?.email)) {
    // hide Ildar Instructor from the list for everyone except ADMIN STUDENTS
    result = result.filter(instructor => instructor.email !== 'ildar.abdulin@gmail.com')
  }
  return result
})

const privateData = useDocument(userStore.userPrivateRef)
const likedUsers = computed(() => {
  return privateData.value?.likedUsers || {}
})

const sortedInstructors = computed(() => {
  return [...filteredByPricingType.value].sort((a, b) => {
    const aLiked = likedUsers.value?.[a.id]
    const bLiked = likedUsers.value?.[b.id]

    if (aLiked && !bLiked) return -1
    if (!aLiked && bLiked) return 1
    return a.name.localeCompare(b.name)
  })
})

const availableLocationsWithCounts = computed(() => {
  return {
    counts: availableInstructors.value.reduce((counts, instructor) => {
      instructor.locations().forEach(location => counts[location.uuid] = (counts[location.uuid] || 0) + 1)
      return counts
    }, {}),
    locations: uniqBy(availableInstructors.value.flatMap(instructor => instructor.locations()), 'uuid'),
  }
})

import LocationsSelector from '@/components/LocationsSelector.vue'

async function selectLocation() {
  const modal = await modalController.create({
    component: LocationsSelector,
    componentProps: {
      items: availableLocationsWithCounts.value.locations,
      selectedItems: booking.location || [],
      mode: 'multiple',
      title: 'Select Location',
    },
  })
  await modal.present()

  const { data } = await modal.onDidDismiss()
  if (data) booking.location = data || []
}

// Commented out for now
// const instructorsByCityCounts = computed(() => {
//   return availableInstructors.value.reduce((counts, instructor) => {
//     instructor.cities().forEach(city => counts[city] = (counts[city] || 0) + 1)
//     return counts
//   }, {})
// })

const pricingTypesWithCounts = computed(() => {
  return Object.entries(ALL_PRICING_TYPES).map(([key, pricingType]) => {
    const count = filteredByCity.value.filter(instructor => instructor.teaches(key)).length
    if (count === 0) return null
    return { key, name: pricingType.name, count }
  }).filter(Boolean)
})

const selectedPricingTypeWithCount = computed(() => {
  let result = ALL_PRICING_TYPES[booking.pricingType].name
  const count = pricingTypesWithCounts.value.find(pricingType => pricingType.key === booking.pricingType)?.count
  if (count) result += ` (${count})`
  return result
})
</script>

<style scoped>
.slide-enter, .slide-leave-to {
  transform: translateY(0);
}
.slide-move {
  transition: transform 0.5s;
}
</style>
