<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ instructor?.name }}
          <span v-if="isLikedInstructor">
            &nbsp;<ion-icon :icon="heart" color="danger" data-testid="liked-instructor-icon" />
          </span>
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding">
      <ion-spinner v-if="isLoading" />
      <error-message v-if="!isLoading && !instructor" title="Instructor not found" />

      <template v-if="instructor">
        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-align-items-center" size="auto">
              <user-avatar :url="instructor.photoURL" :size="100" :name="instructor.name" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <!-- <ion-label><h1>{{ instructor.name }} <img src="https://www.pgaofalberta.com/images/ui/pga-logo.png" alt="placeholder" width="50"></h1></ion-label> -->
              <div v-if="instructor.about">
                <ion-label><strong>About</strong></ion-label>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div data-testid="about-block" style="margin-top: -1em" v-html="formatText(instructor.about)" />
                <hr style="border-bottom: 1px solid rgb(215, 216, 218)">
              </div>

              <div v-if="instructor.specializations" class="ion-margin-top" data-testid="specializations">
                <ion-label><strong>Specializations</strong></ion-label>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div style="margin-top: -1em" v-html="formatText(instructor.specializations)" />
                <hr style="border-bottom: 1px solid rgb(215, 216, 218)">
              </div>
              <!-- <p class="ion-text-center ion-no-margin">
                <ion-button id="show-more" size="small" fill="clear">learn more</ion-button>
              </p> -->
            </ion-col>
          </ion-row>
        </ion-grid>
        <div v-if="subPage == 'pricing'">
          <lesson-type-card
            v-for="lessonType in validLessonTypes"
            :key="lessonType" style="margin-left: -1em; margin-right: -1em"
            :editable="false" :lesson-type="lessonType" :schedules="[]"
          />
        </div>
      </template>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="instructor">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button
                expand="block" fill="outline" color="success"
                :router-link="
                  currentUser ? {name: 'chat', params: {
                    chatId: [currentUser.uid, instructor?.id].sort().join('---')
                  }} : {query: {show: 'login'}}"
                :disabled="currentUser?.uid === instructor?.id"
              >
                <ion-icon slot="start" :icon="chatbubblesOutline" />
                &nbsp;Chat
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block" color="success"
                :router-link="{
                  name: currentUser ? 'bookLesson' : 'guestBookLesson',
                  params: {instructorId: instructor?.id}
                }"
              >
                <ion-icon slot="start" :icon="calendarOutline" />
                &nbsp;Book Lesson
              </ion-button>
            </ion-col>
          </ion-row>
          <package-block-for-golfer v-if="instructor" :instructor="instructor" />
          <ion-row v-if="currentUser && userStore.is.student">
            <ion-col>
              <purchased-packages-block :display-book-button="false" :filter-by-user="instructor" />
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter,
  IonGrid, IonHeader, IonIcon, IonLabel, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar,
} from '@ionic/vue'
import { calendarOutline, chatbubblesOutline, heart } from 'ionicons/icons'
import { computed, ref } from 'vue'
import { useCurrentUser, useDocument } from 'vuefire'

import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import LessonTypeCard from '@/3_widgets/LessonTypeCard.vue'
import PackageBlockForGolfer from '@/3_widgets/packages/PackageBlockForGolfer.vue'
import PurchasedPackagesBlock from '@/3_widgets/packages/PurchasedPackagesBlock.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { formatText } from '@/6_shared/utils/formatText'
import { useUserStore } from '@/stores/user'

const currentUser = useCurrentUser()
const userStore = useUserStore()
const props = defineProps({
  instructorId: { type: String, required: true },
})
const { data: instructor, pending: isLoading } = userStore.student.fn.getInstructor(props.instructorId)
const subPage = ref('pricing')

const validLessonTypes = computed(() => {
  return instructor.value?.instructor.lessonTypes
})

const privateData = useDocument(userStore.userPrivateRef)

const isLikedInstructor = computed(() => {
  return privateData.value?.likedUsers?.[props.instructorId]
})

</script>

<style scoped>
ion-modal#info-modal {
  --width: fit-content;
  --max-width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --max-height: 90%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
</style>
