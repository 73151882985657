<template>
  <ion-page>
    <ion-fab
      slot="fixed" vertical="bottom" horizontal="end"
      :edge="false"
    >
      <ion-button
        fill="solid" data-testid="add-schedule"
        color="success" @click="addLessonType"
      >
        <ion-icon slot="start" :icon="bookOutline" />
        Add a Schedule
      </ion-button>
    </ion-fab>
    <ion-content :fullscreen="true" color="light">
      <div id="lessons_types_block">
        <!--------------->
        <!-- Schedules -->
        <!--------------->

        <template v-if="_userFromStore.instructor.lessonTypes.length == 0">
          <br>
          <ion-card style="text-align:center">
            <br>
            <ion-icon :icon="warningOutline" style="font-size: 64px;" />
            <ion-card-header>
              <ion-card-title>No Schedules</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>Click "Add" to create a new schedule.</p>
            </ion-card-content>
          </ion-card>
        </template>
        <lesson-type-card
          v-for="lessonType in _userFromStore.instructor.lessonTypes" :key="lessonType" :button="false"
          :lesson-type="lessonType"
          @edit:lesson="(editSubPage) => editLessonType(lessonType, editSubPage)"
        />
        <ion-modal ref="lessonModal">
          <lesson-type-modal
            :lesson="selectedLessonType" :locations="_userFromStore.instructor.locations" :sub-page="lessonModalSubPage"
            @update:lesson="saveLessonType($event); lessonModal.$el.dismiss()"
            @remove:lesson="removeLessonType($event); lessonModal.$el.dismiss()"
            @cancel="lessonModal.$el.dismiss()"
          />
        </ion-modal>
      </div>
      <ion-item
        class="ion-padding" detail="true" button="true"
        :router-link="{ name: 'bookingPrioritySettings' }" lines="none" style="padding:0; opacity: 0.9"
        color="light"
      >
        <ion-label>
          Booking Priority:
          <booking-priority-summary :settings="_userFromStore.userData().instructor.bookingPriority" />
        </ion-label>
      </ion-item>
      <div style="margin-bottom: 5em" />
    </ion-content>
  </ion-page>
</template>

<script setup>
import LessonTypeCard from '@/3_widgets/LessonTypeCard.vue'
import { ref } from 'vue'

import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent,
  IonFab, IonIcon, IonItem, IonLabel, IonModal, IonPage,
} from '@ionic/vue'
import { bookOutline, warningOutline } from 'ionicons/icons'

import BookingPrioritySummary from '@/instructors/settings/booking_priority/summary.vue'
import LessonTypeModal from '@/views/instructors/schedules/LessonTypeModal.vue'
import { useUserStore } from '@/stores/user'

const _userFromStore = useUserStore()

const lessonModal = ref()
const lessonModalSubPage = ref()
const selectedLessonType = ref()

function addLessonType() {
  selectedLessonType.value = {}
  lessonModalSubPage.value = 'general'
  lessonModal.value.$el.present()
}

function editLessonType(lessonType, editSubPage) {
  lessonModalSubPage.value = editSubPage
  selectedLessonType.value = lessonType
  lessonModal.value.$el.present()
}

function saveLessonType(lesson) {
  _userFromStore.instructor.fn.addLessonType(lesson)
}

function removeLessonType(lesson) {
  _userFromStore.instructor.fn.removeLessonType(lesson)
}

</script>
