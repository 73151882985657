<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>
        {{ $props.title }}
        <span v-if="!isSingle">({{ workingSelectedValues.length }})</span>
      </ion-title>
    </ion-toolbar>
    <ion-toolbar>
      <ion-searchbar v-model="searchQuery" />
    </ion-toolbar>
  </ion-header>

  <ion-content color="light">
    <ion-list id="modal-list" :inset="false">
      <template v-for="item in filteredItems" :key="item.city">
        <ion-item v-if="!isSingle" lines="full">
          <ion-checkbox
            :value="item" :checked="false"
            @ion-change="selectCity($event, item.city)"
          >
            <strong>All {{ item.city }} Locations</strong>
          </ion-checkbox>
        </ion-item>
        <ion-item-divider v-if="isSingle" sticky="true">{{ item.city }}</ion-item-divider>
        <ion-item v-for="location in item.items" :key="location.uuid" lines="full">
          <ion-checkbox :value="location" :checked="isChecked(location)" @ion-change="checkboxChange($event)">
            <span v-if="!isSingle">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ location.name }}
          </ion-checkbox>
        </ion-item>
      </template>
    </ion-list>
  </ion-content>
  <ion-footer>
    <ion-button expand="block" @click="confirmChanges()">Done</ion-button>
  </ion-footer>
</template>

<script>
import {
  IonButton, IonCheckbox, IonContent, IonFooter, IonHeader, IonItem, IonItemDivider,
  IonList, IonSearchbar, IonTitle, IonToolbar, modalController,
} from '@ionic/vue'
import { computed, defineComponent, ref } from 'vue'
import { groupBy } from 'lodash'

export default defineComponent({
  components: {
    IonButton, IonCheckbox, IonContent, IonHeader, IonFooter, IonItem, IonItemDivider,
    IonList, IonSearchbar, IonTitle, IonToolbar,
  },
  props: {
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    mode: {
      type: String,
      default: 'multiple',
    },
    title: {
      type: String,
      default: 'Select Items',
    },
  },
  emits: ['selection-cancel', 'selection-change'],
  setup(props, { emit }) {
    // group by city & sort by name
    const searchQuery = ref('')
    const isSingle = props.mode === 'single'
    const workingSelectedValues = ref([...props.selectedItems])

    const filteredItems = computed(() => {
      let items = [...props.items]
      if (searchQuery.value) {
        items = items.filter(item =>
          item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
          || item.city.toLowerCase().includes(searchQuery.value.toLowerCase()),
        )
      }
      return Object.entries(groupBy(items, 'city')).map(([city, items]) => ({
        city,
        items: items.sort((a, b) => a.name.localeCompare(b.name)),
      })).sort((a, b) => a.city.localeCompare(b.city))
    })

    const isChecked = (value) => {
      return workingSelectedValues.value.find(item => item?.uuid === value.uuid) !== undefined
    }

    const confirmChanges = () => {
      emit('selection-change', workingSelectedValues.value)
      modalController.dismiss(workingSelectedValues.value)
    }

    const checkboxChange = (ev) => {
      const { checked, value } = ev.detail

      if (isSingle) {
        workingSelectedValues.value = []
      }
      if (checked) {
        workingSelectedValues.value = [...workingSelectedValues.value, value]
        if (isSingle) confirmChanges()
      } else {
        workingSelectedValues.value = workingSelectedValues.value.filter(item => item.uuid !== value.uuid)
      }
    }

    const selectCity = (ev, city) => {
      ev.preventDefault()
      if (ev.detail.checked) {
        workingSelectedValues.value = filteredItems.value.find(item => item.city === city).items
      } else {
        workingSelectedValues.value = []
      }
    }

    return {
      filteredItems,
      searchQuery,
      workingSelectedValues,
      isChecked,
      confirmChanges,
      checkboxChange,
      isSingle,
      selectCity,
    }
  },
})
</script>

<style scoped>
ion-checkbox::part(label) {
  white-space: normal;
  overflow: visible;
}
</style>
