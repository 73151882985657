<template>
  <div v-if="allLessons.length || filteredInvites.length || displayEmptyState">
    <h2 v-if="displayTitle" class="ion-padding-start">Lessons</h2>
    <div>
      <ion-segment
        v-if="allLessons.length || filteredInvites.length" v-model="subPage" scrollable="true"
        data-testid="lessons-statuses"
      >
        <template v-for="state in STATES" :key="state">
          <ion-segment-button v-if="displayEmptyState || filterLessons(state).length" :value="state" :data-testid="`lessons-status-${state}`">
            <ion-label class="ion-text-capitalize">
              {{ state }}
              <span v-if="filterLessons(state).length">
                ({{ filterLessons(state).length }})
              </span>
            </ion-label>
          </ion-segment-button>
        </template>
      </ion-segment>

      <div v-if="lessonsWithNotes.length && subPage == 'completed'" class="toggle-container ion-margin">
        <ion-toggle v-model="showOnlyWithNotes" label-placement="start">
          Show only lessons with notes
        </ion-toggle>
      </div>
    </div>

    <template v-if="filterLessons(subPage).length == 0">
      <br>
      <ion-card style="text-align:center">
        <br>
        <ion-icon :icon="warningOutline" style="font-size: 64px;" />
        <ion-card-header>
          <ion-card-title class="ion-text-capitalize">No {{ subPage }} Lessons</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p v-if="userStore.is.student">Click "Book" to book a new lesson.</p>
          <p v-if="userStore.is.instructor">You will be notified when a student books a lesson with you.</p>
        </ion-card-content>
      </ion-card>
    </template>

    <ion-list v-if="filterLessons(subPage).length > 0">
      <ion-item-group v-for="([month, lessons]) in lessonsGroupsByMonths" :key="month">
        <ion-item-divider v-if="lessons.length > 0" sticky="true">
          <ion-label>{{ month }}</ion-label>
        </ion-item-divider>
        <template v-for="lesson in lessons">
          <lesson-item
            v-if="lesson._type === 'lesson'" :key="lesson.id" :lesson="lesson"
            :current-user="userStore.is.type"
            :router-link="{name: 'lessonPage', params: {lessonId: lesson.id}}"
            :display-user="displayUser"
            :display-notes="showOnlyWithNotes"
          />
          <invite-item
            v-else :key="`invite-${lesson.id}`" :invite="lesson"
            :display-user="!props.filterByUser"
          />
        </template>
      </ion-item-group>
    </ion-list>
    <p v-if="displayStateDescription" class="ion-padding-horizontal">
      <ion-note>
        <span v-if="subPage == 'pending'">Lessons awaiting confirmation or rescheduling</span>
      </ion-note>
    </p>
  </div>
</template>

<script setup>
import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  IonIcon, IonItemDivider, IonItemGroup, IonLabel, IonList, IonNote, IonSegment,
  IonSegmentButton, IonToggle,
} from '@ionic/vue'
import { computed, ref, watchEffect } from 'vue'
import { groupBy } from 'lodash'
import { warningOutline } from 'ionicons/icons'

import InviteItem from '@/shared/components/InviteItem.vue'
import { LessonInvitesRepository } from '@/data/LessonInvite'
import LessonItem from '@/views/golfers/lessons/LessonItem.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  // displayBookButton: { required: false, type: Boolean, default: true },
  filterByUser: { required: false, type: Object, default: null },
  filterByPackage: { required: false, type: Object, default: null },
  displayEmptyState: { required: false, type: Boolean, default: false },
  displayStateDescription: { required: false, type: Boolean, default: true },
  displayTitle: { required: false, type: Boolean, default: false },
})

const STATES = ['pending', 'upcoming', 'completed']

const userStore = useUserStore()

const lessonsRepo = new LessonsRepository()
const InvitesRepo = new LessonInvitesRepository()

const subPage = ref('upcoming')
let allLessons
if (props.filterByUser) {
  STATES.push('cancelled')
  allLessons = lessonsRepo.getLessonsWithStudent(props.filterByUser.id)
} else if (props.filterByPackage) {
  STATES.push('cancelled')
  allLessons = lessonsRepo.getLessonsWithPackage(props.filterByPackage)
} else {
  allLessons = lessonsRepo.getUserLessons()
}

const displayUser = !props.filterByPackage && !props.filterByUser

const invites = InvitesRepo.getInvites()
const filteredInvites = computed(() => {
  if (!props.filterByUser) return invites.value
  return invites.value.filter(invite => invite.instructorId === props.filterByUser.id || invite.studentId === props.filterByUser.id)
})

const lessonsGrouped = computed(() => {
  return groupBy(allLessons.value, lesson => lesson.stateGroup())
})
const invitesGrouped = computed(() => {
  return groupBy(filteredInvites.value, invite => invite.inviteState)
})

watchEffect(() => {
  if (lessonsGrouped.value) {
    let firstStateWithLessons = STATES.find(state => lessonsGrouped.value[state]?.length > 0)
    firstStateWithLessons ||= 'upcoming'
    if (firstStateWithLessons === 'completed' && !props.filterByPackage && !props.filterByUser) {
      firstStateWithLessons = 'upcoming'
    }
    subPage.value = firstStateWithLessons
  }
  if (userStore.is.student && invitesGrouped.value['pending']?.length) {
    subPage.value = 'pending'
  }
})

const lessonsWithNotes = computed(() => {
  return filterLessons(subPage.value).filter(lesson => lesson._type === 'lesson' && lesson.hasInstructorNotes())
})

const showOnlyWithNotes = ref(false)

function filterLessons(state) {
  const lessons = (lessonsGrouped.value[state] || []).sort((lesson1, lesson2) => lesson1.datetime - lesson2.datetime)
  const invites = (invitesGrouped.value[state] || []).sort((invite1, invite2) => invite1.datetime - invite2.datetime)
  lessons.forEach(lesson => lesson._type = 'lesson')
  invites.forEach(invite => invite._type = 'invite')
  const result = [...lessons, ...invites]
  if (state === 'completed') result.reverse()

  return result
}

const lessonsGroupsByMonths = computed(() => {
  const lessonsGroups = filterLessons(subPage.value).reduce((acc, lesson) => {
    const date = new Date(lesson.datetime)
    const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
    acc[monthYear] ||= []
    if (!showOnlyWithNotes.value || subPage.value !== 'completed' || (lesson._type === 'lesson' && lesson.hasInstructorNotes())) {
      acc[monthYear].push(lesson)
    }
    return acc
  }, {})

  // most closest to today first: most recent lessons for "completed", nearest future for "all other states"
  const comparisonFunction = subPage.value === 'completed' ? (a, b) => b[0].localeCompare(a[0]) : (a, b) => a[0].localeCompare(b[0])

  return Object.entries(lessonsGroups).sort(comparisonFunction).map(([monthYear, lessons]) => {
    const monthName = new Date(`${monthYear}-15`).toLocaleString('en-US', { month: 'long', year: 'numeric' })
    return [monthName, lessons]
  })
})
</script>

<style scoped>
ion-badge {
  font-weight: normal;
  margin-left: -3px;
  font-size: 10px;
}

.toggle-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
