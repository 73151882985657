<template>
  <ion-page>
    <ion-tabs>
      <sm-header v-if="!currentUser" />
      <ion-router-outlet />
      <ion-modal
        id="login-modal" ref="loginModalRef" handle="false"
        mode="md" @will-dismiss="hideLoginModal"
      >
        <sign-in-block />
      </ion-modal>
      <ion-tab-bar slot="bottom" data-testid="guest-tabs-menu" style="height: 60px">
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-align-items-center">
            <ion-col size="6" class="ion-padding-horizontal">
              <ion-button
                size="default" color="success" fill="solid"
                expand="block" data-testid="sign-up-button"
                @click="$router.push({ query: { show: 'login', redirect: $route.query.redirect, mode: 'signup' }})"
              >
                <ion-icon slot="start" :icon="personAddOutline" />
                Sign Up
              </ion-button>
            </ion-col>
            <ion-col size="6" class="ion-padding-horizontal">
              <ion-button
                size="default" color="success" fill="outline"
                expand="block" data-testid="login-button"
                @click="$router.push({ query: { show: 'login', redirect: $route.query.redirect, mode: 'signin' }})"
              >
                <ion-icon slot="start" :icon="logInOutline" />
                Login
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import {
  IonButton, IonCol, IonGrid, IonIcon, IonModal, IonPage, IonRouterOutlet,
  IonRow, IonTabBar, IonTabs,
} from '@ionic/vue'
import { logInOutline, personAddOutline } from 'ionicons/icons'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Intercom from '@intercom/messenger-js-sdk'
import { useCurrentUser } from 'vuefire'

import SignInBlock from '@/3_widgets/login/SignInBlock.vue'
import SmHeader from '@/views/layout/SmHeader.vue'

const loginModalRef = ref()
const route = useRoute()
const router = useRouter()
const currentUser = useCurrentUser()

watch(route, () => {
  if (route.query.show === 'login') {
    if (route.params.instructorId) {
      sessionStorage.setItem('instructorId', route.params.instructorId)
    }
    // Timeout is required to display login on page reload
    setTimeout(() => loginModalRef.value?.$el?.present(), 50)
  } else {
    loginModalRef.value?.$el?.dismiss()
  }
}, { immediate: true })

onBeforeUnmount(async () => {
  await loginModalRef.value?.$el?.dismiss()
})

function hideLoginModal() {
  if (route.query.show === 'login') router.back()
}

onMounted(() => {
  const intercomData = {
    app_id: 'dfro638p',
    custom_launcher_selector: '#intercom_custom_launcher',
  }
  Intercom(intercomData)
})

</script>
