import {
  collection, doc,
  increment, limit, orderBy, query, runTransaction, serverTimestamp,
  updateDoc, where,
} from 'firebase/firestore'
import { useCurrentUser, useFirestore } from 'vuefire'
import { defineStore } from 'pinia'
import { formatDistanceToNow } from 'date-fns'

import { chatsInit } from '@/firebase'

export const useChatsStore = defineStore('chats', () => {
  const db = useFirestore()
  const currentUser = useCurrentUser().value

  async function init(id) {
    await chatsInit(id)
  }
  function getChatRef(id) {
    return doc(db, 'chats', id)
  }

  async function markChatAsRead(chat) {
    if (!chat || !chat?.lastMessage) return
    const escapedUid = currentUser.uid.replace(/\./g, '\\.')
    const updates = { [`lastMsgRead.${escapedUid}`]: chat.lastMessage }
    const chatRef = getChatRef(chat.id)
    await updateDoc(chatRef, updates, { merge: true })
  }

  async function addMessage(id, message, userId, createdAt) {
    await runTransaction(db, async (transaction) => {
      const chatRef = getChatRef(id)
      const newMessage = {
        message,
        user: userId || currentUser.uid,
        createdAt: createdAt || serverTimestamp(),
      }
      const newMsgRef = doc(collection(chatRef, 'messages'))
      transaction.set(newMsgRef, newMessage)
      newMessage.id = newMsgRef.id
      transaction.update(chatRef, {
        msgCount: increment(1),
        lastMessage: newMessage,
        lastActivity: serverTimestamp(),
      }, { merge: true })
    })
  }

  function getMessagesRef(id, limitMessages) {
    return query(
      collection(getChatRef(id), 'messages'),
      limit(limitMessages),
      orderBy('createdAt', 'desc'),
    )
  }

  function userChatsRef() {
    return query(
      collection(db, 'chats'),
      where('memberIds', 'array-contains', currentUser.uid),
      orderBy('lastActivity', 'desc'),
    )
  }

  function getChatInfo(chatObj) {
    if (!chatObj) return
    let lastMessagePreview = '', lastActivity = ''
    if (chatObj.lastMessage) {
      lastMessagePreview = chatObj.lastMessage.message
      if (lastMessagePreview.length > 50) {
        lastMessagePreview = lastMessagePreview.slice(0, 50) + '...'
      }
    }
    if (chatObj.lastActivity) {
      lastActivity = formatDistanceToNow(chatObj.lastActivity.seconds * 1000, { addSuffix: true })
    }

    // select a user which is not userStore.userData().id from chatObj.users
    const otherUser = Object.values(chatObj.users).find(user => user.id !== currentUser.uid)
    return {
      otherUser: otherUser,
      isRead: chatObj.lastMsgRead[currentUser.uid]?.id === chatObj.lastMessage?.id,
      lastActivity: lastActivity,
      lastMessagePreview: lastMessagePreview,
      lastMsgReadByOtherUser: chatObj.lastMsgRead[otherUser?.id],
    }
  }

  return {
    init,
    getChatRef,
    getMessagesRef,
    addMessage,
    markChatAsRead,
    userChatsRef,
    getChatInfo,
  }
})
