<template>
  <ion-page>
    <ion-searchbar v-model="searchTerm" />
    <ion-fab
      slot="fixed" ref="addStudentButton"
      vertical="bottom" horizontal="end"
      :edge="false"
    >
      <ion-fab-button color="success" data-testid="add-student-button">
        <ion-icon :icon="personAddOutline" />
      </ion-fab-button>
      <ion-fab-list side="start">
        <ion-button
          fill="solid" color="medium" :router-link="{name: 'newOfflineStudent'}"
          data-testid="add-offline-student-button"
        >
          <ion-icon slot="start" :icon="cloudOfflineOutline" />
          Offline Student
        </ion-button>
        <!--
        TODO: see https://github.com/iabdulin/swingmatch/issues/24
        <ion-button fill="solid" color="medium" :router-link="{name: 'newOfflineStudent'}">
          <ion-icon slot="start" :icon="personAddOutline" />
          Invite Student
        </ion-button> -->
      </ion-fab-list>
    </ion-fab>
    <ion-content color="light" fullscreen="false" data-testid="students-page">
      <ion-segment v-model="subPage">
        <ion-segment-button value="all">
          <ion-label>
            All
            <span v-if="allStudents.length">({{ allStudents.length }})</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="vip">
          <ion-label>
            VIP
            <span v-if="filterStudents('vip', allStudents).length">({{ filterStudents('vip', allStudents).length }})</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="online">
          <ion-label>
            Online
            <span v-if="filterStudents('online', allStudents).length">({{ filterStudents('online', allStudents).length }})</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="offline">
          <ion-label>
            Offline
            <span v-if="filterStudents('offline', allStudents).length">({{ filterStudents('offline', allStudents).length }})</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
      <template v-if="allStudents.length == 0">
        <br>
        <ion-card style="text-align:center">
          <br>
          <ion-icon :icon="warningOutline" style="font-size: 64px;" />
          <ion-card-header>
            <ion-card-title class="ion-text-capitalize">No Students Found</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            Your students will appear here once you have lessons booked.
            <br>
            Click "New Student" to create an offline student.
          </ion-card-content>
        </ion-card>
      </template>
      <template v-if="filteredStudents.length == 0">
        <p class="ion-padding">
          <ion-note>No students matching your search. </ion-note>
          <ion-note v-if="subPage == 'vip'">You can mark students as VIP by editing their profile.</ion-note>
        </p>
      </template>
      <template v-if="filteredStudents.length > 0">
        <ion-list>
          <ion-item
            v-for="student in filteredStudents" :key="student.id" detail="true"
            data-testid="student-item" lines="full" button="true"
            :router-link="{name: 'student', params: {studentId: student.id}}"
          >
            <user-avatar slot="start" :url="student.photoURL" :name="student.name" />

            <ion-label>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span style="text-transform: capitalize;" v-html="highlightSearchTerm(student.name)" />
              <span v-if="student.id.startsWith('offline_')">
                &nbsp;<ion-icon :icon="cloudOfflineOutline" />
              </span>
              <span v-if="student.tags?.includes('vip')">
                &nbsp;<ion-icon :icon="heart" color="danger" data-testid="vip-student-icon" />
              </span>
              <template v-if="student.lessons">
                <ion-note v-if="pendingCount(student)" color="primary">
                  <br>
                  {{ pendingCount(student) }} pending
                </ion-note>
                <ion-note v-if="student.lessons.upcoming.length" color="success">
                  <br>
                  {{ student.lessons.upcoming.length }} upcoming
                </ion-note>
                <ion-note v-if="student.lessons.completed.length">
                  <br>
                  {{ student.lessons.completed.length }} completed
                </ion-note>
              </template>
              <p v-if="student.notes?.length" style="white-space:nowrap; overflow:hidden; text-overflow:ellipsis; max-width:100%; font-style:italic; color:#666">
                "{{ student.notes }}"
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </template>
      <p v-if="filteredStudents.length > 0" class="ion-text-center ion-padding">
        <ion-note>Students: {{ filteredStudents.length }}. Lessons Completed: {{ completedLessonsCount }}.</ion-note>
      </p>
      <div style="margin-bottom: 5em" />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFab,
  IonFabButton, IonFabList, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonSearchbar,
  IonSegment, IonSegmentButton, onIonViewWillLeave,
} from '@ionic/vue'
import { cloudOfflineOutline, heart, personAddOutline, warningOutline } from 'ionicons/icons'
import { computed, ref } from 'vue'
import { escape, groupBy, uniqBy } from 'lodash'

import { LessonInvitesRepository } from '@/data/LessonInvite'
import { LessonsRepository } from '@/data/LessonsRepository'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { useUserStore } from '@/stores/user'

const searchTerm = ref('')
const subPage = ref('all')

const userStore = useUserStore()
const lessonsRepo = new LessonsRepository()
const instructorLessons = lessonsRepo.getInstructorLessons()
const InvitesRepo = new LessonInvitesRepository()

const invites = InvitesRepo.getInvites()
const invitesGroupedByStudent = computed(() => {
  return groupBy(invites.value, 'studentId')
})

const studentsFromLessons = computed(() => {
  const result = {}
  let sortedLessons = [...instructorLessons.value].sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
  // skip cancelled lessons
  sortedLessons = sortedLessons.filter(lesson => lesson.stateGroup() !== 'cancelled')
  // skip lessons for deleted offline students
  sortedLessons = sortedLessons.filter(lesson => !lesson.offlineStudent() || offlineStudents.value.find(student => student.id === lesson.studentId))
  sortedLessons.map((lesson) => {
    const overrideStudent = savedStudents.value.find(student => student.id === lesson.studentId)
    result[lesson.studentId] ||= {
      id: lesson.studentId,
      name: lesson.student.name,
      photoURL: lesson.student.photoURL,
      lessons: { completed: [], pending: [], upcoming: [] },
      ...(overrideStudent || {}),
    }
    result[lesson.studentId].lessons[lesson.stateGroup()] ||= []
    result[lesson.studentId].lessons[lesson.stateGroup()].push(lesson)
  })
  return result
})

const pendingCount = computed(() => {
  return (student) => {
    const pendingInvites = invitesGroupedByStudent.value[student.id] || []
    return student.lessons.pending.length + pendingInvites.length
  }
})

const savedStudents = userStore.instructor.fn.getOfflineStudents()
const offlineStudents = computed(() => {
  return (savedStudents.value || []).filter(student => student.id.startsWith('offline_'))
})

const filterStudents = function (subPage, students) {
  switch (subPage) {
    case 'vip':
      return students.filter(student => student.tags?.includes('vip'))
    case 'online':
      return students.filter(student => !student.id.startsWith('offline_'))
    case 'offline':
      return students.filter(student => student.id.startsWith('offline_'))
    default:
      return students
  }
}

const allStudents = computed(() => {
  const onlineStudents = Object.values(studentsFromLessons.value)
  const allStudentsList = [...onlineStudents, ...offlineStudents.value]

  // select only unique students using lodash
  const uniqueStudents = uniqBy(allStudentsList, 'id')
  return uniqueStudents.sort((a, b) => a.name.localeCompare(b.name))
})

const filteredStudents = computed(() => {
  const result = filterStudents(subPage.value, allStudents.value)
  if (!searchTerm.value) return result
  return result.filter(student =>
    student.name.toLowerCase().includes(searchTerm.value.toLowerCase()),
  )
})

function highlightSearchTerm(text) {
  // html escape everything!
  text = escape(text)
  if (searchTerm.value) {
    searchTerm.value = escape(searchTerm.value)
    return text.replace(new RegExp(searchTerm.value, 'gi'), '<mark>$&</mark>')
  } else {
    return text
  }
}

const completedLessonsCount = computed(() => {
  return filteredStudents.value.reduce((total, student) => {
    const completedCount = student.lessons?.completed?.length || 0
    return total + completedCount
  }, 0)
})

const addStudentButton = ref()
onIonViewWillLeave(() => {
  addStudentButton.value?.$el?.close()
})
</script>
