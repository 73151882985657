import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { getCurrentUser } from 'vuefire'
import { setUser } from '@sentry/vue'

import AccountPage from '@/views/AccountPage.vue'
import BookLessonPage from '@/views/golfers/BookLessonPage.vue'
import BookingPrioritySettingsPage from '@/instructors/settings/booking_priority/index.vue'
import ChatsIndex from '@/2_pages/chat/ChatsIndex.vue'
import ChatsShow from '@/2_pages/chat/ChatsShow.vue'
import EditLessonPage from '@/views/golfers/EditLessonPage.vue'
import FinancialReportsPage from '@/modules/instructors/pages/FinancialReportsPage/index.vue'
import GuestPage from '@/views/GuestPage.vue'
import HomePage from '@/views/HomePage.vue'
import InstructorNewLessonPage from '@/modules/instructors/pages/NewLessonPage/index.vue'
import InstructorNewPackagePage from '@/modules/instructors/pages/NewPackagePage/index.vue'
import InstructorPage from '@/views/golfers/InstructorPage.vue'
import InvitePage from '@/shared/pages/InvitePage.vue'
import LessonsPage from '@/views/golfers/LessonsPage.vue'
import NewLessonPage from '@/views/golfers/NewLessonPage.vue'
import PackagePage from '@/modules/instructors/pages/PackagePage/index.vue'
import SaveStudentPage from '@/2_pages/instructors/SaveStudentPage.vue'
import SchedulesPage from '@/views/instructors/SchedulesPage.vue'
import ShowLessonPage from '@/views/golfers/ShowLessonPage.vue'
import StudentPage from '@/2_pages/instructors/StudentPage.vue'
import StudentsPage from '@/2_pages/instructors/StudentsPage.vue'

import GuestTabsLayout from '@/2_pages/guests/TabsLayout.vue'
import InsideTabsPage from '@/views/InsideTabsPage.vue'
import NotFoundPage from '@/shared/pages/NotFoundPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/guest/home',
    name: 'index',
  },
  {
    path: '/guest/',
    component: GuestTabsLayout,
    meta: { requiresGuest: true },
    children: [
      {
        path: '',
        redirect: '/guest/home',
      },
      {
        name: 'guestHome',
        path: 'home',
        meta: { title: 'SwingMatch Golf' },
        component: GuestPage,
      },
      {
        name: 'guestNewLesson',
        path: 'lessons/new',
        component: NewLessonPage,
      },
      {
        name: 'guestInstructor',
        path: 'instructors/:instructorId',
        props: true,
        component: InstructorPage,
      },
      {
        name: 'guestBookLesson',
        path: 'instructors/:instructorId/book',
        props: true,
        component: BookLessonPage,
      },
    ],
  },
  {
    path: '/my/',
    component: InsideTabsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/my/home',
      },
      {
        name: 'insideHome',
        path: 'home',
        meta: { title: 'Welcome to SwingMatch!' },
        component: HomePage,
      },
      {
        name: 'chats',
        path: 'chats',
        meta: { title: 'Chats' },
        component: ChatsIndex,
      },
      {
        name: 'chat',
        path: 'chats/:chatId',
        props: true,
        component: ChatsShow,
      },
      {
        name: 'schedules',
        path: 'schedules',
        meta: { title: 'Schedules' },
        component: SchedulesPage,
      },
      {
        name: 'students',
        path: 'students',
        meta: { title: 'Students' },
        component: StudentsPage,
      },
      {
        name: 'newOfflineStudent',
        path: 'students/new',
        component: SaveStudentPage,
      },
      {
        name: 'editOfflineStudent',
        path: 'students/:studentId/edit',
        props: true,
        component: SaveStudentPage,
      },
      {
        name: 'student',
        path: 'students/:studentId',
        props: true,
        component: StudentPage,
      },
      {
        name: 'createLessonPage',
        path: 'students/:studentId/lessons/new',
        props: true,
        component: InstructorNewLessonPage,
      },
      {
        name: 'createPackagePage',
        path: 'students/:studentId/packages/new',
        props: true,
        component: InstructorNewPackagePage,
      },
      {
        name: 'account',
        path: 'account',
        meta: { title: 'My Profile' },
        component: AccountPage,
      },
      {
        name: 'lessons',
        path: 'lessons',
        meta: { title: 'My Lessons' },
        component: LessonsPage,
      },
      {
        name: 'lessonPage',
        path: 'lessons/:lessonId',
        props: true,
        component: ShowLessonPage,
      },
      {
        name: 'lessonEditPage',
        path: 'lessons/:lessonId/edit',
        props: true,
        component: EditLessonPage,
      },
      {
        name: 'newLesson',
        path: 'lessons/new',
        meta: { title: 'Find Instructor', allowedForGuests: true },
        component: NewLessonPage,
      },
      {
        name: 'instructor',
        path: 'instructors/:instructorId',
        props: true,
        component: InstructorPage,
        meta: { allowedForGuests: true },
      },
      {
        name: 'invite',
        path: 'invites/:inviteId',
        props: true,
        component: InvitePage,
      },
      {
        name: 'bookLesson',
        path: 'instructors/:instructorId/book',
        props: true,
        component: BookLessonPage,
        meta: { allowedForGuests: true },
      },
      {
        path: '/my/reports',
        name: 'reports',
        component: FinancialReportsPage,
        meta: { title: 'Financial Reports' },
      },
      {
        name: 'package',
        path: 'packages/:packageId',
        props: true,
        component: PackagePage,
      },
      {
        name: 'bookingPrioritySettings',
        path: 'booking-priority-settings',
        meta: { title: 'Booking Priority' },
        component: BookingPrioritySettingsPage,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundPage,
    meta: { title: 'Page Not Found' },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async function (to) {
  const currentUser = await getCurrentUser()
  if (currentUser && currentUser.email) {
    console.log(`User is logged in. Setting user for Sentry. ${currentUser.email}`)
    setUser({ email: currentUser.email || '' })
  }
  if (to.meta.requiresAuth && to.meta.allowedForGuests && !currentUser) {
    console.log('User is required but not logged in. The page can be accessed by guests. Redirecting to guest page.')
    return to.fullPath.replace('/my/', '/guest/')
  }
  if (to.meta.requiresAuth && !currentUser) {
    console.log('User is required but not logged in. Redirecting to login page.')
    return {
      name: 'guestHome',
      query: to.fullPath !== '/' ? { redirect: to.fullPath } : {},
    }
  }
  if (to.meta.requiresGuest && currentUser) {
    console.log('Guest is required but user is logged in. Redirecting to to the corresponding logged in user page or user dashboard.')
    const redirectTo = to.fullPath.includes('/guest/') ? to.fullPath.replace('/guest/', '/my/') : { name: 'insideHome' }
    return redirectTo
  }
})

export default router
