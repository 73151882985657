<template>
  <ion-page data-testid="booking-priority-settings-page">
    <ion-content>
      <ion-item lines="none">
        <div>
          <h1>Manage Booking Priority</h1>
          <p class="description">
            Set when different groups can book your time slots, giving priority access to preferred clients.
          </p>
        </div>
      </ion-item>

      <template v-if="settings">
        <!-- VIP Golfers -->
        <ion-item :lines="settings.vip.enabled ? 'none' : 'full'" class="ion-padding-bottom">
          <div>
            <h3>1. VIP Golfers (Priority Access)</h3>
            <p>
              <ion-note>
                You can mark golfers as <strong>VIP</strong>s on their profile page.
                By default, they can book all your available timeslots.
              </ion-note>
            </p>
            <ion-toggle
              v-model="settings.vip.enabled"
              mode="md" fill="outline"
            >
              <ion-label>Limit availability for VIPs</ion-label>
            </ion-toggle>
          </div>
        </ion-item>
        <ion-item v-if="settings.vip.enabled" lines="full" class="ion-padding-bottom">
          <ion-input
            v-model.number="settings.vip.days" type="number"
            mode="md" fill="outline"
            min="1" max="365"
          >
            <ion-label slot="end">days in advance to book</ion-label>
          </ion-input>
        </ion-item>

        <!-- Course Members -->
        <ion-item :lines="settings.courseMembers.enabled ? 'none' : 'full'" class="ion-padding-bottom">
          <div>
            <h3>2. Course Members (Second Priority)</h3>
            <p>
              <ion-note>
                You can select specific golf courses and give their members priority access to your time slots.
              </ion-note>
            </p>
            <p v-if="userStore.instructor.lessonTypes.length === 0">
              <ion-note color="danger">
                You need to add at least one schedule to enable this feature.
              </ion-note>
            </p>
            <ion-toggle
              v-model="settings.courseMembers.enabled"
              mode="md" fill="outline"
              :disabled="userStore.instructor.lessonTypes.length === 0"
            >
              <ion-label>Enable availability for members</ion-label>
            </ion-toggle>
          </div>
        </ion-item>
        <template v-if="settings.courseMembers.enabled">
          <ion-item lines="none" class="ion-padding-bottom">
            <ion-select
              v-model="settings.courseMembers.courses" multiple="true" placeholder="Select courses"
              label="Courses" mode="md" fill="outline"
              :compare-with="(a, b) => a?.uuid === b?.uuid"
              :class="{ 'ion-invalid ion-touched': settings.courseMembers.courses.length === 0 }"
            >
              <ion-select-option
                v-for="course in courses"
                :key="course.uuid"
                :value="course"
              >
                {{ course.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="full" class="ion-padding-bottom">
            <ion-input
              v-if="settings.courseMembers.enabled" v-model.number="settings.courseMembers.days" type="number"
              min="1" max="365" mode="md"
              fill="outline"
            >
              <ion-label slot="end">days in advance to book</ion-label>
            </ion-input>
          </ion-item>
        </template>

        <!-- General Public -->
        <ion-item lines="none">
          <div>
            <h3>3. General Public (Last Priority)</h3>
            <p>
              <ion-note>
                Control public access to your remaining availability.
                You can either keep it private or allow bookings a few days in advance.
                This helps fill any remaining slots in your schedule while ensuring VIPs and course members get priority access.
              </ion-note>
            </p>
          </div>
        </ion-item>
        <ion-item :lines="settings.public.availability === 'days' ? 'none' : 'full'" class="ion-padding-bottom">
          <ion-select
            v-model="settings.public.availability"
            placeholder="Select availability"
            mode="md" fill="outline"
            label="Availability"
          >
            <ion-select-option value="all">All Timeslots</ion-select-option>
            <ion-select-option value="days">Limited Availability (days in advance)</ion-select-option>
            <ion-select-option value="never">Not Available</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="settings.public.availability === 'days'" lines="full" class="ion-padding-bottom">
          <ion-input
            v-model.number="settings.public.days"
            type="number"
            min="0"
            max="365"
            mode="md" fill="outline"
          >
            <ion-label slot="end">days in advance to book</ion-label>
          </ion-input>
        </ion-item>
        <ion-item lines="none">
          <div>
            <h2>Summary</h2>
            <ion-note>Please double check your restriction settings before saving.</ion-note>
            <booking-priority-summary :settings="settings" />
          </div>
        </ion-item>

        <ion-button
          expand="block"
          color="primary"
          class="ion-margin-top ion-margin-bottom"
          @click="saveSettings"
        >
          Save Settings
        </ion-button>
      </template>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonButton, IonContent, IonInput, IonItem, IonLabel, IonNote, IonPage, IonSelect, IonSelectOption, IonToggle,
  toastController,
} from '@ionic/vue'
import { computed, onMounted, ref } from 'vue'
import { useDocument } from 'vuefire'

import BookingPrioritySummary from './summary.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const { data: profile, promise: profilePromise } = useDocument(userStore.userDocRef)

const settings = ref(null)

profilePromise.value.then(() => {
  settings.value = profile.value?.instructor?.bookingPriority || {}
  const DEFAULT_SETTINGS = {
    vip: {
      days: 60,
      enabled: false,
    },
    courseMembers: {
      enabled: false,
      courses: [],
      days: 60,
    },
    public: {
      availability: 'all', // all, days, never
      days: 60,
    },
  }
  settings.value = Object.assign(DEFAULT_SETTINGS, settings.value)
})

const courses = computed(() => userStore.instructor.lessonTypes.map(lt => lt.location))
onMounted(async () => {
  // Load saved settings from user store/database
  // This is where you'd fetch the existing settings
})

async function saveSettings() {
  settings.value.courseMembers.days ||= 0
  settings.value.public.days ||= 0
  settings.value.vip.days ||= 0
  try {
    await sendRequest('Updating Profile...', async () => {
      await userStore.fn.updateProfile({
        instructor: {
          bookingPriority: settings.value,
        },
      })
    }, settings.value)
    const toast = await toastController.create({
      message: 'Booking Priority Settings Saved',
      duration: 1000,
      color: 'success',
    })
    await toast.present()
    console.log('Saving settings:', settings.value)
  } catch (error) {
    console.error('Error saving settings:', error)
  }
}
</script>
